import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import NumberTicker from "../../animations/NumberTicker";


interface StatCardProps {
    percentage: number;
    className?: string;
    isMobile?: boolean;
  }
  
  export const StatCard: React.FC<StatCardProps> = ({ 
    percentage, 
    className = "",
    isMobile = false 
  }) => (
    <div className={`rounded-[30px] ${isMobile ? 'bg-[#FCF1E7]': 'bg-none'} p-4 ${className}`}>
      <p className={`text-black font-regular ${isMobile ? 'text-[18px]' : 'md:text-[36px]'}`}>
        <NumberTicker value={percentage} />
        <span className={`italic font-bold text-primary-foreground ${isMobile ? 'text-[36px]' : 'text-[50px] sm:text-[73px]'}`}>
          %
        </span>{" "}
        of women with PCOS remain{" "}
        <span className="font-bold text-primary-foreground italic">
          undiagnosed*
        </span>
      </p>
    </div>
  );