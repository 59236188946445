import React from "react";
import { ExternalLink} from "lucide-react";
import ReactGA from "react-ga4";

interface TestData {
    Company: string;
    Biomarkers: string;
    Results: string;
    Support: string;
    GP: boolean;
    Diagnosis: string;
    Price: number;
    Link: string;
  }

const handleLinkClick = (linkLabel: string) => {
    ReactGA.event({
        category: 'Table Link',
        action: `Clicked ${linkLabel} Link`,
        label: linkLabel,
    });
};


const DesktopTable: React.FC<{ data: TestData[] }> = ({ data }) => (
    <div className="rounded-[34px] bg-offWhite border border-primary-foreground/60 shadow-custom overflow-hidden">
      {/* Table Header Section */}
      <div className="p-6 border-b border-primary-foreground/20">
        <h2 className="text-2xl font-bold text-black">Test Comparison</h2>
        <p className="text-black/70 mt-2">Compare features and pricing across providers</p>
      </div>
  
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-offWhite border-b border-primary-foreground/20">
              <th className="px-6 py-5 text-left text-[16px] font-bold text-black">
                Provider
              </th>
              <th className="px-6 py-5 text-left text-[16px] font-bold text-black min-w-[200px]">
                Biomarkers
              </th>
              <th className="px-6 py-5 text-left text-[16px] font-bold text-black">
                Support
              </th>
              <th className="px-6 py-5 text-center text-[16px] font-bold text-black">
                GP Review
              </th>
              <th className="px-6 py-5 text-right text-[16px] font-bold text-black">
                Price
              </th>
              <th className="px-6 py-5 text-center text-[16px] font-bold text-black">
                Details
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-primary-foreground/10">
            {data.map((test, index) => (
              <tr
                key={`${test.Company}-${index}`}
                className="bg-offWhite hover:bg-primary-foreground/5 transition-colors"
              >
                <td className="px-6 py-5">
                  <span className="font-semibold text-black">{test.Company}</span>
                </td>
                <td className="px-6 py-5">
                  <div className="space-y-2">
                    <span className="inline-flex items-center border shadow-custom border-primary-foreground/90 rounded-full bg-primary-foreground/10 px-3 py-1.5 text-sm font-semibold text-primary-foreground">
                      {test.Biomarkers.split(",").length} markers
                    </span>
                    <p className="text-md text-black/70">{test.Biomarkers}</p>
                  </div>
                </td>
                <td className="px-6 py-5">
                  <p className="text-md text-black/70">{test.Support}</p>
                </td>
                <td className="px-6 py-5 text-center">
                  <span
                    className={`inline-flex items-center justify-center rounded-full px-3 py-1.5 text-md font-semibold ${
                      test.GP
                        ? "bg-primary-foreground text-offWhite"
                        : "bg-muted/70 text-secondary"
                    }`}
                  >
                    {test.GP ? "Included" : "No"}
                  </span>
                </td>
                <td className="px-6 py-5 text-right">
                  <span className="font-semibold text-black">
                    {test.Price ? `£${test.Price}` : "N/A"}
                  </span>
                </td>
                <td className="px-6 py-5 text-center">
                  <a
                    href={test.Link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => handleLinkClick(test.Company)}
                    className="inline-flex items-center gap-2 px-4 py-2 rounded-[22px] bg-primary-foreground text-offWhite text-sm font-medium hover:bg-primary-foreground/90 w-fit transition-colors shadow-custom"
                  >
                    View Details
                    <ExternalLink className="h-4 w-4" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

export default DesktopTable