import React, { useState } from "react";
import { motion } from "framer-motion";
import landingImage1 from "../../../assets/images/landingImage1.png";
import PulsatingButton from "../../buttons/PulsatingButton";
import AnimatedText from "../../animations/AnimatedText";
import ReactGA from "react-ga4";

interface FormData {
  name: string;
  email: string;
}

const DesktopHero: React.FC<{
  textAnimationVariants: any;
  mounted: boolean;
  handleScrollToSection: (sectionId: string) => void;
}> = ({ textAnimationVariants, mounted, handleScrollToSection }) => {
  const [formData, setFormData] = useState<FormData>({ name: "", email: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setIsSubmitted(false);

    ReactGA.event({
      category: 'Buttons',
      action: 'Signup Submitted',
      label: 'Signup Button',
    });

    try {
      const response = await fetch(
        "https://paia-backend.azurewebsites.net/api/waitinglist",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.status === 409) {
        setErrorMessage(
          "You are already on the waitlist! We'll notify you when the app launches."
        );
      } else if (response.ok) {
        setIsSubmitted(true);
        setFormData({ name: "", email: "" });
      } else {
        throw new Error("Something went wrong. Please try again later.");
      }
    } catch (error: any) {
      setErrorMessage(error.message || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section
      id="landing"
      aria-label="Hero section showcasing Paia's services and community"
      className="relative pt-32 overflow-hidden"
    >
      {/* Background animation */}
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: { duration: 1.2, ease: [0.6, 0.05, 0.01, 0.9] },
        }}
        className="absolute z-0 pointer-events-none"
        style={{
          width: "950px",
          height: "800px",
          top: "107px",
          left: "30%",
          background: "#FEE8DA",
          borderRadius: "50%",
          transform: "translate(-50%, -50%)",
          filter: "blur(40px)",
        }}
      />

      {/* Main content container */}
      <div className="relative z-10 w-screen max-w-[1300px] mx-auto flex flex-col lg:flex-row lg:items-center justify-center px-4 md:px-8 lg:px-8 pt-16">
        {/* Text and form container */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="flex flex-col items-center lg:items-start text-center lg:text-left lg:pr-8 py-8 w-full lg:w-[60%] xl:w-[70%]"
        >
          <h2 className="text-black max-w-[38rem] text-[28px] md:text-[32px] lg:text-[40px] font-regular text-center lg:text-left leading-snug mb-16 md:mb-24">
            {[
              "Supporting women",
              "navigate their",
              "chronic journey",
              "with",
              "confidence",
            ].map((text, index) => (
              <motion.span
                key={text}
                custom={index}
                variants={textAnimationVariants}
                initial="hidden"
                animate="visible"
                className={`${
                  index % 2 === 0 ? "text-primary-foreground font-semibold" : ""
                } ${index === 4 ? "italic" : ""}`}
              >
                {text}{" "}
              </motion.span>
            ))}
          </h2>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 1.4 }}
            className="w-full"
          >
            <p className="text-black text-[24px] md:text-[28px] font-bold mb-6">
              Be the first to experience the launch of our app
            </p>

            <form onSubmit={handleSubmit} className="space-y-6 max-w-xl">
              <div className="space-y-4">
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="md:w-3/4"
                >
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    className="shadow-custom w-full px-4 py-2.5 rounded-xl border border-primary/50 
                             focus:outline-none focus:ring-2 focus:ring-primary
                             transition-all duration-300 ease-in-out"
                    aria-label="Your name"
                  />
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="md:w-3/4"
                >
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter e-mail address"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    className="shadow-custom w-full px-4 py-2.5 rounded-xl border border-primary/50 
                             focus:outline-none focus:ring-2 focus:ring-primary
                             transition-all duration-300 ease-in-out"
                    aria-label="Your email"
                  />
                </motion.div>
              </div>

              <div className="mt-8">
                <PulsatingButton
                  type="submit"
                  onClick={() => handleScrollToSection("events")}
                  disabled={isLoading}
                >
                  <p className="font-medium text-[18px] lg:text-[22px]">
                    {isLoading ? "Signing up..." : "Sign up"}
                  </p>
                </PulsatingButton>
              </div>
            </form>

            {isSubmitted && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="mt-4 text-secondary font-medium text-center lg:text-left"
              >
                Thank you for signing up! We'll be in touch soon.
              </motion.div>
            )}

            {errorMessage && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="mt-4 text-primary-foreground font-medium text-center lg:text-left"
              >
                {errorMessage}
              </motion.div>
            )}
          </motion.div>
        </motion.div>

        {/* Image container */}
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.5 }}
          className="w-full lg:w-[40%] xl:w-[30%] flex justify-center items-center mt-12 lg:mt-0"
        >
          <img
            src={landingImage1}
            alt="Landing"
            className="w-auto h-auto max-h-[18rem] md:max-h-[24rem] lg:max-h-[32rem] xl:max-h-[40rem] 
                     object-contain transform transition-transform duration-700 
                     hover:scale-105 lg:-mr-8"
            loading="eager"
          />
        </motion.div>
      </div>

      <div className="max-w-4xl mx-auto">

      <AnimatedText
        // Optional custom overrides
        customTextSize="text-[34px]"
        customMaxWidth="max-w-[85%]"
        customMarginY="mt-20 mb-10"
      />
      </div>
    </section>
  );
};

export default DesktopHero;
