import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollToTop = () => {
      try {
        // Using 'auto' instead of 'instant' as it's a valid ScrollBehavior
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto'
        });
      } catch (error) {
        // Fallback for older browsers
        window.scrollTo(0, 0);
      }
    };

    // Add a small delay to ensure DOM updates are complete
    const timeoutId = setTimeout(scrollToTop, 0);

    return () => clearTimeout(timeoutId);
  }, [pathname]);

  return null;
};
