import React from "react";
import { motion } from "framer-motion";
import { AlertCircle } from "lucide-react";

interface UnsavedChangesAlert {
  show: boolean;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const Alert: React.FC<UnsavedChangesAlert> = ({
  show,
  message,
  onConfirm,
  onCancel,
}) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-white rounded-xl p-6 max-w-md mx-4 shadow-lg"
      >
        <div className="flex items-start gap-3 mb-4">
          <AlertCircle className="h-5 w-5 text-primary-foreground flex-shrink-0 mt-1" />
          <p className="text-secondary">{message}</p>
        </div>
        <div className="flex justify-end gap-3">
          <button
            onClick={onCancel}
            className="px-4 py-2 text-secondary/70 hover:text-secondary 
                     transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-primary/60 text-secondary rounded-lg 
                     hover:bg-primary-foreground hover:text-offWhite transition-colors duration-200"
          >
            Continue
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default Alert;
