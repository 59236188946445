import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { CheckCircle, XCircle, Loader } from "lucide-react";
import { createClient } from "@supabase/supabase-js";
import validator from "validator";
import screenshot from "../../assets/images/screenshotThree.png";

const supabaseUrl = "https://hzprryancrhfcpvudiqp.supabase.co";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imh6cHJyeWFuY3JoZmNwdnVkaXFwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjA5NTM5MjQsImV4cCI6MjAzNjUyOTkyNH0.oPDN-20n1XqP47yqTlrL7FAgdG9RrERhJdf0gxBqd38";
const supabase = createClient(supabaseUrl, supabaseAnonKey);

interface FormStatus {
  type: "error" | "success" | null;
  message: string;
}

const AppSignup: React.FC = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState<FormStatus>({ type: null, message: "" });
  const [loading, setLoading] = useState(false);

  const isEmailExists = async (email: string) => {
    const { data, error } = await supabase
      .from("emails")
      .select("emails_web")
      .eq("emails_web", email);

    if (error) {
      console.error("Error checking email:", error);
      return false;
    }
    return data.length > 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Reset status
    setStatus({ type: null, message: "" });

    // Validation
    if (!name.trim()) {
      setStatus({ type: "error", message: "Please enter your name" });
      return;
    }

    if (!email.trim()) {
      setStatus({ type: "error", message: "Please enter your email" });
      return;
    }

    if (!validator.isEmail(email)) {
      setStatus({ type: "error", message: "Please enter a valid email" });
      return;
    }

    setLoading(true);

    try {
      const exists = await isEmailExists(email);

      if (exists) {
        setStatus({
          type: "error",
          message: "This email is already registered",
        });
        return;
      }

      const { error } = await supabase
        .from("emails")
        .insert([{ name: name, emails_web: email }]);

      if (error) throw error;

      // Success
      setStatus({
        type: "success",
        message:
          "You're on the waitlist! We'll notify you when the app launches.",
      });
      setEmail("");
      setName("");

      // Clear success message after 5 seconds
      setTimeout(() => {
        setStatus({ type: null, message: "" });
      }, 5000);
    } catch (error) {
      console.error("Submission error:", error);
      setStatus({
        type: "error",
        message: "Something went wrong. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      className="bg-[#FAFAFA] rounded-[40px] pt-6 px-6 overflow-hidden"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="max-w-4xl flex flex-col gap-6 md:gap-2 md:flex-row items-center md:items-start justify-between">
        <div className="w-full md:w-auto">
          {/* Header */}
          <motion.div
            className="text-center space-y-6 my-8"
            variants={itemVariants}
          >
            <div className="space-y-2">
              <motion.h3
                className="text-2xl font-bold text-left text-primary-foreground"
                whileInView={{ opacity: 1, x: 0 }}
                initial={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.6 }}
              >
                Be the first to experience the launch of our app!
              </motion.h3>
            </div>
          </motion.div>

          {/* Form */}
          <motion.form
            onSubmit={handleSubmit}
            className="space-y-4"
            variants={itemVariants}
          >
            <div className="space-y-3 flex flex-col">
              <motion.input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter name"
                disabled={loading}
                className="shadow-custom px-4 py-2.5 rounded-xl border border-primary/50 
                          focus:outline-none focus:ring-2 focus:ring-primary
                          transition-all duration-300 ease-in-out
                          disabled:opacity-50 disabled:cursor-not-allowed"
                whileFocus={{ scale: 1.01 }}
                variants={itemVariants}
              />
              <motion.input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter e-mail address"
                disabled={loading}
                className="shadow-custom px-4 py-2.5 rounded-xl border border-primary/50 
                          focus:outline-none focus:ring-2 focus:ring-primary
                          transition-all duration-300 ease-in-out
                          disabled:opacity-50 disabled:cursor-not-allowed"
                whileFocus={{ scale: 1.01 }}
                variants={itemVariants}
              />
            </div>

            <motion.button
              type="submit"
              disabled={loading}
              className="w-full sm:w-auto px-12 py-2 bg-primary-foreground text-offWhite font-medium 
                        rounded-[33px] hover:bg-primary-foreground/90 transition-all duration-300
                        flex items-center justify-center gap-2 disabled:opacity-50 
                        disabled:cursor-not-allowed"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              variants={itemVariants}
            >
              {loading ? (
                <Loader className="h-4 w-4 animate-spin" />
              ) : (
                <>Sign up</>
              )}
            </motion.button>

            {/* Status Messages */}
            <AnimatePresence mode="wait">
              {status.message && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.3 }}
                  className={`flex items-center gap-2 text-sm ${
                    status.type === "error" ? "text-red-600" : "text-green-600"
                  }`}
                >
                  {status.type === "error" ? (
                    <XCircle className="h-4 w-4 flex-shrink-0" />
                  ) : (
                    <CheckCircle className="h-4 w-4 flex-shrink-0" />
                  )}
                  {status.message}
                </motion.div>
              )}
            </AnimatePresence>
          </motion.form>
        </div>

        {/* Screenshot */}
        <motion.img
          src={screenshot}
          alt="app screenshot"
          className="w-[12rem] md:w-[16rem] h-auto"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          whileHover={{ scale: 1.03 }}
        />
      </div>
    </motion.div>
  );
};

export default AppSignup;
