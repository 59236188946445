import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Mail,
  Lock,
  X,
  AlertCircle,
  ExternalLink,
  CheckCircle,
} from "lucide-react";
import axios from "axios";
import { useConsultationGuide } from "../../../hooks/useConsultationGuide";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Alert from "./Alert";
import ReactGA from 'react-ga4';

interface FormData {
  name: string;
  email: string;
  acceptTerms: boolean;
  subscribeUpdates: boolean;
}

interface ConsultationGuideFormProps {
  onSubmitSuccess: () => void;
  identifiedPatternsCount: number;
  answers: Record<string, number>;
}

interface FormStatus {
  isLoading: boolean;
  error: string | null;
  success: boolean;
}

const TestingInfoSection = () => (
  <div className="mt-12 space-y-6">
    <div className="bg-primary shadow-custom rounded-lg h-[0.1rem] mb-4"></div>
    <div className="text-left space-y-6">
      <h3 className="text-[18px] md:text-[22px] font-semibold text-black">
        Additional Testing Information
      </h3>

      <div className="grid gap-4">
        <div className="bg-white rounded-[17px] p-4 border border-primary-foreground/50">
          <h4 className="text-[16px] md:text-[18px] font-semibold text-black mb-2">
            NHS Testing Options
          </h4>
          <p className="text-[14px] md:text-[16px] text-black/70">
            Your GP can arrange blood tests and, if needed, a pelvic ultrasound
            through the NHS. These tests are available at no cost when
            clinically indicated.
          </p>
        </div>

        <div className="bg-white rounded-[17px] p-4 border border-primary-foreground/50">
          <h4 className="text-[16px] md:text-[18px] font-semibold text-black mb-2">
            Private Testing
          </h4>
          <p className="text-[14px] md:text-[16px] text-black/70">
            Private clinics offer comprehensive hormone panels with shorter
            waiting times. Your consultation guide includes details about
            available options.
          </p>
        </div>

        <a
          href="https://www.monash.edu/medicine/sphpm/mchri/pcos"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-between bg-[#FCF1E7] rounded-[17px] p-4 border border-primary/10
                     hover:bg-[#F4DECB] transition-colors duration-200"
        >
          <div>
            <h4 className="text-[16px] md:text-[18px] font-semibold text-black mb-1">
              Monash Guidelines
            </h4>
            <p className="text-[14px] md:text-[16px] text-black/70">
              Learn more about international PCOS diagnosis guidelines
            </p>
          </div>
          <ExternalLink className="h-5 w-5 text-primary-foreground flex-shrink-0" />
        </a>
      </div>
    </div>
  </div>
);

const PrivacyModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50"
      >
        <div
          className="absolute inset-0 bg-black/50 backdrop-blur-sm"
          onClick={onClose}
        />
        <div className="flex items-center justify-center min-h-screen p-4">
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="bg-white rounded-[34px] w-full max-w-lg p-6 relative z-10 shadow-custom"
          >
            <div className="flex items-start justify-between mb-6">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-[#FCF1E7] rounded-full">
                  <Lock className="h-5 w-5 text-primary-foreground" />
                </div>
                <h2 className="text-[20px] md:text-[24px] font-bold text-black">
                  Privacy Policy
                </h2>
              </div>
              <button
                onClick={onClose}
                className="p-2 hover:bg-[#FCF1E7] rounded-full transition-colors"
                aria-label="Close privacy policy"
              >
                <X className="h-5 w-5 text-black/70" />
              </button>
            </div>

            <div className="space-y-6 text-[15px] md:text-[17px] text-black/70">
              <div className="space-y-4">
                <h3 className="font-semibold text-black">Data Collection</h3>
                <p>We collect your name and email address solely to:</p>
                <ul className="space-y-2 pl-4">
                  <li>• Send your personalised consultation guide</li>
                  <li>
                    • Send optional updates about new health tools (if opted in)
                  </li>
                </ul>
              </div>

              <div className="space-y-4">
                <h3 className="font-semibold text-black">Data Protection</h3>
                <ul className="space-y-2">
                  <li>• Your data is encrypted and stored securely</li>
                  <li>• We never share your information with third parties</li>
                  <li>
                    • You can unsubscribe or request data deletion at any time
                  </li>
                </ul>
              </div>
            </div>

            <button
              onClick={onClose}
              className="w-full mt-8 p-3 bg-primary-foreground text-white font-medium rounded-[17px]
                       hover:bg-primary/90 transition-colors duration-200"
            >
              I Understand
            </button>
          </motion.div>
        </div>
      </motion.div>
    )}
  </AnimatePresence>
);

const SuccessMessage = () => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="text-center space-y-6"
  >
    <div className="w-16 h-16 bg-[#FCF1E7] rounded-full flex items-center justify-center mx-auto">
      <Mail className="h-8 w-8 text-primary-foreground" />
    </div>
    <div className="space-y-3">
      <h3 className="text-[20px] md:text-[24px] font-bold text-black">
      Your guide is on its way to your inbox!
      </h3>
      <div className="space-y-3">
        <div className="bg-[#FCF1E7] p-4 rounded-[17px] text-left border border-primary/10">
          <h4 className="text-[16px] md:text-[18px] font-semibold text-black mb-2">
            Important: Check Your Spam Folder
          </h4>
          <p className="text-[14px] md:text-[16px] text-black/70 mb-2">
            If you don't see the email within 5 minutes:
          </p>
          <ul className="space-y-2 text-[14px] md:text-[16px] text-black/70">
            <li className="flex items-center gap-2">
              <CheckCircle className="h-4 w-4 text-primary-foreground flex-shrink-0" />
              Check your spam/junk folder
            </li>
            <li className="flex items-center gap-2">
              <CheckCircle className="h-4 w-4 text-primary-foreground flex-shrink-0" />
              Mark our email as "not spam" if found there
            </li>
            <li className="flex items-center gap-2">
              <CheckCircle className="h-4 w-4 text-primary-foreground flex-shrink-0" />
              <a
                href="mailto:hello@paiapcos.com"
                className="text-secondary hover:text-secondary/50 transition-colors duration-200"
              >
                Contact Us - hello@paiapcos.com
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </motion.div>
);

const ConsultationGuideForm: React.FC<ConsultationGuideFormProps> = ({
  onSubmitSuccess,
  identifiedPatternsCount,
  answers,
}) => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    acceptTerms: false,
    subscribeUpdates: false,
  });

  const [formStatus, setFormStatus] = useState<FormStatus>({
    isLoading: false,
    error: null,
    success: false,
  });

  const [fieldErrors, setFieldErrors] = useState({
    name: "",
    email: "",
  });

  const [showNavigationAlert, setShowNavigationAlert] = useState(false);

  const mutation = useConsultationGuide();
  const navigate = useNavigate();

  const isFormValid =
    formData.name.trim() &&
    formData.email.trim() &&
    formData.email.includes("@") &&
    formData.acceptTerms;

  const validateField = (name: string, value: string) => {
    switch (name) {
      case "name":
        return !value.trim() ? "Please enter your name" : "";
      case "email":
        return !value.trim()
          ? "Please enter your email address"
          : !value.includes("@")
          ? "Please enter a valid email address"
          : "";
      default:
        return "";
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;

    setFormData((prev) => ({
      ...prev,
      [name]: inputValue,
    }));

    if (type !== "checkbox") {
      setFieldErrors((prev) => ({
        ...prev,
        [name]: validateField(name, inputValue as string),
      }));
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldErrors((prev) => ({
      ...prev,
      [name]: validateField(name, value),
    }));
  };

  const handlePrivacyPolicyClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (formData.name || formData.email || !formStatus.success) {
      setShowNavigationAlert(true);
    } else {
      navigate("/privacy-policy");
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isFormValid) return;

    ReactGA.event({
      category: 'Buttons',
      action: 'Guide Submitted',
      label: 'Submit Guide Button',
    });

    const guideRequest = {
      name: formData.name.trim(),
      email: formData.email.trim(),
      answers,
      marketingConsent: formData.subscribeUpdates,
    };

    console.log(guideRequest)
    try {
      setFormStatus({ isLoading: true, error: null, success: false });

      const response = await axios.post(
        "https://paia-backend.azurewebsites.net/api/send-guide",
        guideRequest
      );

      if (response.status === 200) {
        setFormStatus({ isLoading: false, error: null, success: true });
        onSubmitSuccess();
      }
    } catch (error) {
      let errorMessage = "Failed to send guide. Please try again.";
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 429) {
          errorMessage = "Too many attempts. Please wait a moment.";
        } else if (!error.response) {
          errorMessage = "Connection error. Please check your internet.";
        }
      }
      setFormStatus({ isLoading: false, error: errorMessage, success: false });
    }
  };

  if (formStatus.success) {
    return (
      <>
        <SuccessMessage />
        <TestingInfoSection />
      </>
    );
  }

  return (
    <div className="space-y-6 max-w-xl mt-8 mx-auto">
      <p className="text-[15px] text-left border border-primary bg-offWhite rounded-[17px] p-4 border-[#F4DECB] md:text-[17px] text-black/80">
        We'll send you a detailed guide with discussion points for your GP and
        educational resources about your health patterns
      </p>

      <form onSubmit={handleSubmit} className="space-y-6">
        <AnimatePresence mode="wait">
          {formStatus.error && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="flex items-center gap-2 p-4 bg-[#FEE2E2] text-[#991B1B] rounded-[17px]"
            >
              <AlertCircle className="h-5 w-5 flex-shrink-0" />
              <p className="text-[15px] md:text-[17px]">{formStatus.error}</p>
            </motion.div>
          )}
        </AnimatePresence>

        <div className="space-y-4">
          <div className="space-y-2">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              onBlur={handleBlur}
              placeholder="Your name"
              className={`w-full px-4 py-2.5 rounded-lg shadow-custom border
                ${
                  fieldErrors.name
                    ? "border-primary-foreground bg-red-50"
                    : "border-primary/50 bg-background"
                } 
                focus:outline-none focus:ring-2 focus:ring-primary 
                placeholder:text-grey`}
              disabled={formStatus.isLoading}
            />
            {fieldErrors.name && (
              <p className="text-primary-foreground font-medium text-[14px] pl-1">
                {fieldErrors.name}
              </p>
            )}
          </div>

          <div className="space-y-2">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              onBlur={handleBlur}
              placeholder="Your email"
              className={`w-full px-4 py-2.5 rounded-lg shadow-custom border
                ${
                  fieldErrors.email
                    ? "border-primary-foreground bg-red-50"
                    : "border-primary/50 bg-background"
                } 
                focus:outline-none focus:ring-2 focus:ring-primary 
                placeholder:text-grey`}
              disabled={formStatus.isLoading}
            />
            {fieldErrors.email && (
              <p className="text-primary-foreground font-medium text-[14px] pl-1">
                {fieldErrors.email}
              </p>
            )}
          </div>
        </div>

        <div className="space-y-4 text-left">
          <label className="flex items-start gap-3 cursor-pointer group">
            <input
              type="checkbox"
              name="acceptTerms"
              checked={formData.acceptTerms}
              onChange={handleInputChange}
              className="mt-1 p-2 md:p-6 bg-offWhite shadow-custom rounded border border-primary/50 
                text-primary focus:ring-primary"
              disabled={formStatus.isLoading}
            />
            <span className="text-sm text-black/70 group-hover:text-secondary">
              I agree to the{" "}
              <Link
                to="/privacy-policy"
                onClick={handlePrivacyPolicyClick}
                className="text-primary-foreground hover:underline focus:outline-none focus:ring-2 focus:ring-primary rounded px-1"
              >
                privacy policy
              </Link>{" "}
              and consent to receive my consultation guide
            </span>
          </label>

          <label className="flex items-start gap-3 cursor-pointer group">
            <input
              type="checkbox"
              name="subscribeUpdates"
              checked={formData.subscribeUpdates}
              onChange={handleInputChange}
              className="mt-1 p-2 md:p-6 bg-offWhite shadow-custom rounded border border-primary/50 
                text-primary focus:ring-primary"
              disabled={formStatus.isLoading}
            />
            <span className="text-sm text-black/70 group-hover:text-secondary">
              Keep me updated about new health tools, educational events, and
              news from Paia <span className="font-bold">(optional)</span>
            </span>
          </label>
        </div>

        <div className="space-y-3">
          <button
            type="submit"
            disabled={!isFormValid || formStatus.isLoading}
            className={`w-full p-3 rounded-lg font-medium transition-all duration-200 flex items-center justify-center
              ${
                !isFormValid || formStatus.isLoading
                  ? "bg-primary/50 cursor-not-allowed"
                  : "bg-primary-foreground hover:bg-primary/90"
              } text-white`}
          >
            {formStatus.isLoading ? (
              <span className="flex items-center gap-2">
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                  className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full"
                />
                Sending...
              </span>
            ) : (
              "Get Consultation Guide"
            )}
          </button>
          <p className="text-xs text-center text-secondary/60">
            Your data is protected and never shared with third parties
          </p>
        </div>
      </form>

      <Alert
        show={showNavigationAlert}
        message="You have unsaved changes. Your consultation guide information will be lost if you leave. Do you want to continue?"
        onConfirm={() => {
          setShowNavigationAlert(false);
          navigate("/privacy-policy");
        }}
        onCancel={() => setShowNavigationAlert(false)}
      />
    </div>
  );
};

export default ConsultationGuideForm;
