import React, { useState, useEffect } from "react";
import { Heart, ChevronDown } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const EducationalDisclaimer: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  // Auto-collapse after delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsExpanded(false);
    }, 6000); // 6 seconds delay

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-[80%] my-2">
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="w-full bg-gradient-to-r from-primary/5 to-primary/10 rounded-[17px] border border-primary/10"
      >
        {/* Minimalist Header */}
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="w-full flex items-center justify-between p-3 md:p-4 hover:bg-primary/5 transition-colors duration-200"
          aria-expanded={isExpanded}
        >
          <div className="flex items-center gap-2">
            <Heart className="h-4 w-4 text-primary-foreground" />
            <span className="text-[13px] md:text-[14px] text-secondary/80">
              Important information
            </span>
          </div>
          <ChevronDown 
            className={`h-4 w-4 text-primary-foreground transition-transform duration-200 ${
              isExpanded ? "rotate-180" : ""
            }`}
          />
        </button>

        {/* Expandable Content */}
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="overflow-hidden"
            >
              <div className="px-4 pb-4">
                <p className="text-[13px] md:text-[14px] text-secondary/80 leading-relaxed">
                  While PCOS often goes undiagnosed, this tool helps you understand your health patterns.
                  Use these insights for informed discussions with healthcare providers during your regular check-ups.
                  This is an educational resource and does not diagnose PCOS.
                </p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default EducationalDisclaimer;