import React from 'react';

const QuickFilters: React.FC<{
    onFilterChange: (filter: string) => void;
    activeFilter: string;
  }> = ({ onFilterChange, activeFilter }) => (
    <div className="flex gap-2 overflow-x-auto pb-2 md:pb-0 md:flex-wrap">
      {['All', 'GP Review', 'Under £100'].map(filter => (
        <button
          key={filter}
          onClick={() => onFilterChange(filter)}
          className={`whitespace-nowrap px-4 py-2 rounded-full text-sm font-bold transition-all duration-200 ${
            activeFilter === filter
              ? 'bg-primary-foreground text-offWhite shadow-sm'
              : 'bg-primary-foreground/10 text-secondary hover:bg-primary-foreground/60'
          }`}
        >
          {filter}
        </button> 
      ))}
    </div>
  );

  export default QuickFilters