import React from "react";
import { motion } from "framer-motion";
import { AlertCircle, ShieldCheck, Stethoscope, BookOpen, X } from "lucide-react";

interface TermsAlertProps {
  show: boolean;
  onClose: () => void;
}

const TermsAlert: React.FC<TermsAlertProps> = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-start justify-center z-50 overflow-y-auto">
      <div className="min-h-screen w-full py-8 px-4 sm:px-6">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          className="bg-white rounded-xl shadow-lg max-w-2xl mx-auto relative"
        >
          {/* Close button for mobile */}
          <button
            onClick={onClose}
            className="absolute right-4 top-4 p-2 text-secondary/60 hover:text-secondary transition-colors duration-200 sm:hidden"
            aria-label="Close"
          >
            <X className="h-5 w-5" />
          </button>

          <div className="p-4 sm:p-6 space-y-6 max-h-[calc(100vh-4rem)] overflow-y-auto">
            {/* Header */}
            <div className="flex items-start gap-3 border-b border-primary/10 pb-4">
              <ShieldCheck className="h-6 w-6 text-primary-foreground flex-shrink-0 hidden sm:block" />
              <div className="flex-1 min-w-0">
                <h2 className="text-lg sm:text-xl font-semibold text-secondary truncate">
                  Medical Disclaimer & Terms
                </h2>
                <p className="text-secondary/70 text-sm mt-1">
                  Educational Tool Developed in Consultation with Healthcare Professionals
                </p>
              </div>
            </div>

            {/* Content Sections */}
            <div className="space-y-6 text-secondary/80">
              {/* Educational Purpose Section */}
              <section className="space-y-3">
                <div className="flex items-center gap-2 text-secondary">
                  <BookOpen className="h-5 w-5 text-primary-foreground flex-shrink-0" />
                  <h3 className="font-medium">Educational Purpose Only</h3>
                </div>
                <div className="pl-4 sm:pl-7 space-y-2 text-sm">
                  <p>This PCOS symptom screening tool:</p>
                  <ul className="list-disc pl-4 space-y-1">
                    <li>Is designed solely for educational and informational purposes</li>
                    <li>Cannot and does not provide medical diagnosis</li>
                    <li>Should not replace professional medical advice or examination</li>
                    <li>Is not a substitute for consultation with qualified healthcare providers</li>
                  </ul>
                </div>
              </section>

              {/* Medical Acknowledgment Section */}
              <section className="space-y-3">
                <div className="flex items-center gap-2 text-secondary">
                  <Stethoscope className="h-5 w-5 text-primary-foreground flex-shrink-0" />
                  <h3 className="font-medium">Medical Acknowledgment</h3>
                </div>
                <div className="pl-4 sm:pl-7 space-y-2 text-sm">
                  <p>By using this tool, you acknowledge and agree that:</p>
                  <ul className="list-disc pl-4 space-y-1">
                    <li>Results are for informational purposes only and are not a diagnosis</li>
                    <li>No doctor-patient relationship is established through use of this tool</li>
                    <li>Individual symptoms may be related to conditions other than PCOS</li>
                    <li>You should not delay or avoid seeking medical care based on this tool</li>
                    <li>All medical concerns should be discussed with qualified healthcare professionals</li>
                  </ul>
                </div>
              </section>

              {/* Professional Development Notice */}
              <div className="bg-primary/5 rounded-lg p-4 text-sm">
                <p>
                  <span className="font-medium">Professional Development:</span>{" "}
                  While this tool has been developed in consultation with registered healthcare professionals, 
                  it serves purely as an educational resource. The information provided is general in nature 
                  and not personalised medical advice.
                </p>
              </div>

              {/* Emergency Notice */}
              <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 text-sm">
                <div className="flex items-start gap-3">
                  <AlertCircle className="h-5 w-5 text-yellow-600 flex-shrink-0 mt-0.5" />
                  <p className="text-yellow-800">
                    If you are experiencing severe symptoms or have immediate health concerns, 
                    please contact your healthcare provider or seek emergency medical attention immediately.
                  </p>
                </div>
              </div>
            </div>

            {/* Action Button */}
            <div className="flex justify-end pt-4 border-t border-primary/10">
              <button
                onClick={onClose}
                className="w-full sm:w-auto px-6 py-2.5 bg-primary text-secondary rounded-lg 
                         hover:bg-primary-foreground transition-colors duration-200 font-medium"
              >
                I Understand
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default TermsAlert;