import React from "react";
import { motion } from "framer-motion";
import { CheckCircle } from "lucide-react";

interface ProgressBarProps {
  progress: number;
  currentQuestion: number;
  totalQuestions: number;
  maxVisited: number;
  onQuestionClick: (index: number) => void;
  visitedQuestions: number[];
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  currentQuestion,
  totalQuestions,
  maxVisited,
  onQuestionClick,
  visitedQuestions,
}) => (
  <div className="space-y-4 my-6 px-2 sm:px-4">
    {/* Header */}
    <div className="flex justify-between items-center text-sm text-secondary/90">
      <span className="font-medium">
        Question {currentQuestion + 1} of {totalQuestions}
      </span>
      <span>{Math.round(progress)}% Complete</span>
    </div>

    {/* Progress Track Container */}
    <div className="relative pt-4 pb-6">
      {/* Main Progress Bar */}
      <div className="w-full bg-muted rounded-full h-2 mb-2">
        <motion.div
          className="h-full bg-primary-foreground rounded-full"
          initial={{ width: 0 }}
          animate={{ width: `${Math.min(100, Math.max(0, progress))}%` }}
          transition={{ duration: 0.3, ease: "easeOut" }}
        />
      </div>

      {/* Question Markers */}
      <div className="absolute top-0 left-0 w-full flex justify-between px-[2px]">
        {Array.from({ length: totalQuestions }).map((_, index) => {
          const isVisited = visitedQuestions.includes(index);
          const isCurrent = index === currentQuestion;
          const isAvailable = index <= maxVisited;

          return (
            <div 
              key={index} 
              className="relative flex items-center justify-center"
              style={{ width: `${100 / totalQuestions}%` }}
            >
              <button
                onClick={() => isAvailable && onQuestionClick(index)}
                disabled={!isAvailable}
                className={`
                  group relative w-8 h-8 flex items-center justify-center
                  transition-all duration-200 rounded-full
                  ${isAvailable ? 'cursor-pointer hover:bg-primary/10' : 'cursor-not-allowed'}
                `}
                aria-label={`Go to question ${index + 1}`}
              >
                <motion.div
                  initial={false}
                  animate={{
                    scale: isCurrent ? 1 : 0.85,
                  }}
                  className={`
                    w-4 h-4 rounded-full transition-all duration-200
                    ${isCurrent 
                      ? 'bg-primary-foreground ring-2 ring-primary-foreground/50 ring-offset-2' 
                      : isVisited 
                        ? 'bg-primary-foreground/80' 
                        : 'bg-muted'}
                    ${isAvailable ? 'group-hover:bg-primary-foreground' : 'opacity-50'}
                  `}
                >
                  {isVisited && !isCurrent && (
                    <CheckCircle 
                      className="w-3 h-3 text-white absolute 
                               top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" 
                    />
                  )}
                </motion.div>

                {/* Enhanced Tooltip */}
                <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 
                               opacity-0 group-hover:opacity-100 transition-all duration-200 
                               pointer-events-none z-10">
                  <div className="bg-secondary text-white text-xs px-2 py-1 
                                rounded shadow-lg whitespace-nowrap">
                    Question {index + 1}
                    {isVisited && " (Answered)"}
                    {isCurrent && " (Current)"}
                  </div>
                </div>

               
              </button>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

export default ProgressBar;