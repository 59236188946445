import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate, useBeforeUnload } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  ChevronDown,
  Heart,
  CheckIcon,
  HomeIcon,
  ChevronRight,
  Instagram,
  Users,
  Video,
  Share2,
} from "lucide-react";
import document from "../../../assets/icons/summary.png";
import arrow from "../../../assets/icons/arrow-right.png";
import ConsultationGuideForm from "./ConsultationGuideForm";
import logoImg from "../../../assets/icons/paia.png";
import Alert from "./Alert";
import check from "../../../assets/icons/check.png";
import downArrow from "../../../assets/icons/arrow-down.png";
import EducationalDisclaimer from "./EducationalDisclaimer";

interface SectionVariant {
  containerStyles: string;
  headerStyles: string;
  contentStyles: string;
  iconContainerStyles: string;
}

interface CollapsibleSectionProps {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  isExpanded: boolean;
  onToggle: () => void;
  variant?: "primary" | "secondary";
  showNotification?: boolean;
  leftIcon?: {
    src?: string; // For images
    component?: React.ReactNode; // For components
    alt?: string;
  };
  rightIcon?: {
    src?: string;
    component?: React.ReactNode;
    alt?: string;
  };
  hideLeftIcon?: boolean;
}

interface NoSymptomsMessageProps {
  className?: string;
}
interface Option {
  text: string;
  score: number;
  explanation?: string;
  isPositive?: boolean;
  isNegative?: boolean;
}

interface Question {
  key: string;
  text: string;
  options: Option[];
  whyThisMatters: string;
  references: Array<{ label: string; url: string }>;
  prevalenceData?: string;
  resultPositive?: string;
  resultNegative?: string;
}

interface ResultsCardProps {
  answers: Record<string, number>;
  questions: Question[];
}

const useNavigationProtection = (isProtected: boolean) => {
  const navigate = useNavigate();

  // Remove the useBeforeUnload hook from here as we'll handle it in the component
  const showNavigationAlert = useCallback(() => {
    if (isProtected) {
      return window.confirm(
        "You haven't downloaded your consultation guide yet. Your results may be lost if you leave. Are you sure you want to continue?"
      );
    }
    return true;
  }, [isProtected]);

  useEffect(() => {
    if (!isProtected) return;

    const preventNavigation = (e: PopStateEvent) => {
      e.preventDefault();
      if (showNavigationAlert()) {
        window.removeEventListener("popstate", preventNavigation);
        navigate(-1);
      } else {
        window.history.pushState(null, "", window.location.pathname);
      }
    };

    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", preventNavigation);

    return () => {
      window.removeEventListener("popstate", preventNavigation);
    };
  }, [isProtected, navigate, showNavigationAlert]);

  return {
    handleNavigation: useCallback(
      (to: string) => {
        if (!isProtected || showNavigationAlert()) {
          navigate(to);
        }
      },
      [isProtected, navigate, showNavigationAlert]
    ),
  };
};

interface NavigationHeaderProps {
  onShare: () => void;
  showShareTooltip: boolean;
  handleNavigation: (to: string) => void;
}

// Separate components for better organization
const NavigationHeader: React.FC<NavigationHeaderProps> = ({
  onShare,
  showShareTooltip,
  handleNavigation,
}) => (
  <header className="flex justify-between items-center px-0 py-4 md:p-4 sticky top-0 bg-white/95 backdrop-blur-sm z-20 border-b border-primary/60">
    <button
      onClick={() => handleNavigation("/")}
      className="flex items-center gap-2 rounded-[12px] border border-primary-foreground/60 p-2 bg-offWhite text-primary-foreground hover:text-secondary transition-colors duration-200"
    >
      <HomeIcon className="h-4 w-4 md:h-5 md:w-5" />
      <span className=" text-[12px] md:text-sm font-semibold">Back Home</span>
    </button>
    <img src={logoImg} alt="logo" className="h-auto w-16 md:w-24" />
    <div className="relative">
      <button
        onClick={onShare}
        className="flex items-center gap-2 text-offWhite rounded-[12px] border border-primary p-2 bg-primary-foreground hover:text-offWhite/40 transition-colors duration-200"
        aria-label="Share Tool"
      >
        <Share2 className="h-4 w-4 md:h-5 md:w-5" />
        <span className=" text-[12px] font-semibold md:text-sm">
          Share Tool
        </span>
      </button>
      <AnimatePresence>
        {showShareTooltip && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="absolute right-0 top-full mt-2 px-3 py-2 bg-secondary text-primary text-sm rounded-lg shadow-lg whitespace-nowrap"
          >
            Link copied to clipboard!
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  </header>
);

const sectionVariants: Record<string, SectionVariant> = {
  primary: {
    containerStyles:
      "w-full bg-white rounded-[34px] border border-primary mt-4",
    headerStyles:
      "w-full p-4 md:p-6 text-left flex items-center justify-between",
    contentStyles: "p-6",
    iconContainerStyles: "border border-primary rounded-[16px] p-2",
  },
  secondary: {
    containerStyles:
      "w-full bg-[#FCF1E7] rounded-[34px] border border border-primary mt-4",
    headerStyles: "w-full p-6 text-left flex items-center justify-between",
    contentStyles: "p-6 rounded-[34px] bg-[#FCF1E7]",
    iconContainerStyles: "border border border-primary rounded-[16px] p-2",
  },
};

// Components
const NotificationPing: React.FC = () => (
  <div className="absolute top-1 right-1 flex h-4 w-4">
    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-foreground opacity-75"></span>
    <span className="relative inline-flex rounded-full h-4 w-4 bg-primary-foreground"></span>
  </div>
);

const NoSymptomsMessage: React.FC<NoSymptomsMessageProps> = ({ className }) => (
  <div
    className={`text-center space-y-4 flex flex-col items-center ${className}`}
  >
    <div className="mx-auto w-fit p-4 bg-primary/5 rounded-full">
      <CheckIcon className="h-8 w-8 text-primary-foreground" />
    </div>
    <h3 className="text-xl font-semibold text-secondary">
      No Symptoms Identified
    </h3>
    <p className="text-base text-secondary/70 max-w-lg mx-auto">
      Based on your responses, we haven't identified any significant
      PCOS-related patterns. However, if you have concerns about your health, we
      still recommend discussing them with your healthcare provider.
    </p>
  </div>
);

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  subtitle,
  children,
  isExpanded,
  onToggle,
  variant = "primary",
  showNotification = false,
  leftIcon,
  rightIcon,
  hideLeftIcon = false,
}) => {
  const styles = sectionVariants[variant];

  const renderIcon = (iconData: {
    src?: string;
    component?: React.ReactNode;
    alt?: string;
  }) => {
    if (iconData?.component) return iconData.component;
    if (iconData?.src)
      return (
        <img
          src={iconData.src}
          alt={iconData.alt || ""}
          className="w-8 h-8 md:w-12 md:h-12 object-contain"
        />
      );
    return null;
  };

  const defaultRightIcon = (
    <ChevronDown
      className={`h-10 w-10 text-primary-foreground transition-transform duration-200 ${
        isExpanded ? "rotate-180" : ""
      }`}
    />
  );

  return (
    <div className={styles.containerStyles}>
      <div className="relative">
        {showNotification && <NotificationPing />}
        <button
          onClick={onToggle}
          className={`${styles.headerStyles}`}
          aria-expanded={isExpanded}
        >
          <div className="flex items-center gap-3">
            {!hideLeftIcon && (
              <div className={styles.iconContainerStyles}>
                {renderIcon(leftIcon || { src: document })}
              </div>
            )}
            <div>
              <h2 className="font-bold text-secondary text-[16px] md:text-[24px]">
                {title}
              </h2>
              {subtitle && (
                <p className="font-medium text-accent/80 text-[14px] md:text-[22px]">
                  {subtitle}
                </p>
              )}
            </div>
          </div>
          {rightIcon ? renderIcon(rightIcon) : defaultRightIcon}
        </button>
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
          >
            <div className={styles.contentStyles}>{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const ResultsCard: React.FC<ResultsCardProps> = ({ answers, questions }) => {
  const [showShareTooltip, setShowShareTooltip] = useState(false);
  const [expandedSections, setExpandedSections] = useState({
    symptoms: true,
    otherSymptoms: false,
    nextSteps: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  // const { handleNavigation } = useNavigationProtection(!isSubmitted);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    acceptTerms: false,
    subscribeUpdates: false,
  });

  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (!isSubmitted) {
        e.preventDefault();
        e.returnValue =
          "You haven't downloaded your consultation guide yet. Your results may be lost if you leave.";
        return e.returnValue;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isSubmitted]);

  const handleFormSubmitSuccess = useCallback(() => {
    setIsSubmitted(true);
    setExpandedSections((prev) => ({ ...prev, nextSteps: true }));
  }, []);

  const identifiedPatterns = Object.entries(answers)
    .filter(([_, score]) => score > 0)
    .map(([key]) => questions.find((q) => q.key === key))
    .filter(Boolean);

  const hasSymptoms = identifiedPatterns.length > 0;

  const otherPatterns = Object.entries(answers)
    .filter(([_, score]) => score === 0)
    .map(([key]) => questions.find((q) => q.key === key))
    .filter(Boolean);

  // Prevent navigation/refresh if guide not downloaded
  useBeforeUnload((event) => {
    if (!isSubmitted) {
      event.preventDefault();
      event.returnValue =
        "You haven't downloaded your consultation guide yet. Your results may be lost if you leave.";
    }
  });

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (!isSubmitted) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isSubmitted]);

  const handleShare = async () => {
    const shareUrl = "https://paiapcos.com/pcos-screening";
    const shareText = `I just completed a women's health assessment - a great tool for understanding your health patterns.`;

    try {
      if (navigator.share) {
        await navigator.share({
          title: "Health Assessment Results",
          text: shareText,
          url: shareUrl,
        });
      } else {
        await navigator.clipboard.writeText(shareUrl);
        setShowShareTooltip(true);
        setTimeout(() => setShowShareTooltip(false), 2000);
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.acceptTerms) return;

    try {
      // Add your submission logic here
      setIsSubmitted(true);
      // Close the next steps section after successful submission
      setExpandedSections((prev) => ({ ...prev, nextSteps: false }));
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const handleNavigation = (target: string) => {
    if (!isSubmitted) {
      setShowAlert(true); // Show the alert
      setTargetNavigation(target); // Set the navigation target
    } else {
      navigate(target); // Navigate directly if submitted
    }
  };

  const [confirmNavigate, setConfirmNavigate] = useState(false);
  const [targetNavigation, setTargetNavigation] = useState<string | null>(null); // Track the target

  const handleCancelNavigation = () => {
    setShowAlert(false);
    setTargetNavigation(null); // Clear the target
  };

  const handleTestComparisonClick = (e: React.MouseEvent) => {
    if (!confirmNavigate && !isSubmitted) {
      e.preventDefault(); // Temporarily block navigation
      setShowAlert(true); // Show the alert to the user
    }
  };

  const handleConfirmNavigation = () => {
    setShowAlert(false);
    if (targetNavigation) {
      navigate(targetNavigation); // Navigate to the target after confirmation
    }
    setTargetNavigation(null); // Clear the target
  };

  return (
    <div className="min-h-screen bg-background">
      <NavigationHeader
        onShare={handleShare}
        showShareTooltip={showShareTooltip}
        handleNavigation={handleNavigation}
      />

      <main className="max-w-4xl mx-auto sm:px-4 py-8 flex flex-col items-center">
        {/* Assessment Complete Banner */}
        <div className="w-fit mx-auto flex items-center px-4 py-2 bg-[#FAFAFA] shadow-custom rounded-[16px] border border-primary mb-2 md:mb-6">
          <img
            src={check}
            alt="check icon"
            className="w-5 h-5 md:h-7 md:w-7 text-primary-foreground mr-2"
          />
          <span className="font-semibold text-primary-foreground text-[16px] md:text-[20px]">
            Assessment Complete
          </span>
        </div>

        {/* Results Summary */}
        <div className="mt-6 bg-offWhite w-full rounded-[34px] shadow-custom py-6 px-4 md:px-12 text-center flex flex-col items-center">
          <h1 className="text-[22px] md:text-[29px] font-bold text-black mb-1 md:mb-6">
            Your Health Patterns Overview
          </h1>
          <EducationalDisclaimer />
          <div className="p-3 bg-white rounded-[25px] shadow-custom mx-auto w-fit border border-primary flex items-center gap-2 my-4 md:my-6">
            <div className="text-3xl font-bold text-primary-foreground mb-1">
              {identifiedPatterns.length}
            </div>
            <div className="text-black/90 font-bold text-[18px] md:text-[22px]">
              Potential{" "}
              {identifiedPatterns.length === 1 ? "Symptom" : "Symptoms"}{" "}
              identified
            </div>
          </div>

          {hasSymptoms ? (
            <>
              <CollapsibleSection
                title="Your symptom summary"
                subtitle="Review your responses"
                isExpanded={expandedSections.symptoms}
                onToggle={() =>
                  setExpandedSections((prev) => ({
                    ...prev,
                    symptoms: !prev.symptoms,
                  }))
                }
                leftIcon={{
                  src: document,
                  alt: "Summary icon",
                }}
                rightIcon={{
                  component: (
                    <ChevronDown className="w-8 h-8 text-primary-foreground" />
                  ),
                }}
              >
                <>
                  <div className="bg-primary shadow-custom rounded-lg h-[0.1rem] mb-4"></div>
                  <p className="text-[18px] text-left md:text-[22px] font-medium text-secondary my-3">
                    Symptoms you reported
                  </p>
                  <div className="text-left space-y-4">
                    {identifiedPatterns.map(
                      (question, index) =>
                        question && (
                          <div
                            key={index}
                            className="bg-offWhite border border-muted rounded-[17px] p-4"
                          >
                            <h4 className="text-[16px] md:text-[20px] font-semibold text-black mb-2">
                              {question.key}
                            </h4>
                            <p className="text-[13px] md:text-[17px] text-black/70">
                              {question.resultPositive}
                            </p>
                          </div>
                        )
                    )}
                  </div>
                </>
              </CollapsibleSection>
            </>
          ) : (
            <NoSymptomsMessage className="mt-8" />
          )}
          <div className="w-full mt-6 bg-white rounded-[24px] border border-primary p-6">
            <h2 className="text-[20px] md:text-[24px] font-bold text-secondary mb-4">
              Get Your Consultation Guide
            </h2>
            <ConsultationGuideForm
              onSubmitSuccess={handleFormSubmitSuccess}
              identifiedPatternsCount={identifiedPatterns.length}
              answers={answers}
            />
          </div>
          {/* Other Symptoms Section */}
          <CollapsibleSection
            title={
              hasSymptoms
                ? "Learn about other PCOS symptoms"
                : "Learn about PCOS symptoms"
            }
            isExpanded={expandedSections.otherSymptoms}
            onToggle={() =>
              setExpandedSections((prev) => ({
                ...prev,
                otherSymptoms: !prev.otherSymptoms,
              }))
            }
            variant="secondary"
            hideLeftIcon
            rightIcon={{
              component: (
                <img
                  src={downArrow}
                  alt="down arrow"
                  className={`w-8 h-8 md:w-10 md:h-10 text-primary-foreground transition-transform duration-200 ${
                    expandedSections.otherSymptoms ? "rotate-180" : ""
                  }`}
                />
              ),
            }}
          >
            <div className="space-y-4">
              {/* If no symptoms identified, show all symptoms */}
              {!hasSymptoms ? (
                <>
                  <p className="text-left text-[14px] md:text-[16px] text-secondary/80 mb-4">
                    Here are common PCOS-related patterns to be aware of:
                  </p>
                  {questions.map(
                    (question, index) =>
                      question && (
                        <div
                          key={index}
                          className="text-left bg-white border border-muted rounded-[17px] p-4"
                        >
                          <h4 className="text-[16px] md:text-[20px] font-semibold text-black mb-2">
                            {question.key}
                          </h4>
                          <p className="text-[13px] md:text-[17px] text-black/70">
                            {question.whyThisMatters}
                          </p>
                        </div>
                      )
                  )}
                </>
              ) : (
                // If symptoms were identified, show only other symptoms
                otherPatterns.map(
                  (question, index) =>
                    question && (
                      <div
                        key={index}
                        className="text-left bg-white border border-muted rounded-[17px] p-4"
                      >
                        <h4 className="text-[16px] md:text-[20px] font-semibold text-black mb-2">
                          {question.key}
                        </h4>
                        <p className="text-[13px] md:text-[17px] text-black/70">
                          {question.resultNegative}
                        </p>
                      </div>
                    )
                )
              )}
            </div>
          </CollapsibleSection>

          <CollapsibleSection
            title="Next Steps"
            subtitle="Understanding your journey forward"
            isExpanded={expandedSections.nextSteps}
            onToggle={() =>
              setExpandedSections((prev) => ({
                ...prev,
                nextSteps: !prev.nextSteps,
              }))
            }
            leftIcon={{
              src: arrow,
              alt: "arrow right icon",
            }}
          >
            <>
              {/* Testing Information */}
              <div className="bg-white rounded-[24px] md:p-6">
                {/* <div className="flex items-start gap-3 mb-4">
                  <div className="text-left">
                    <h3 className="px-2 text-[18px] md:text-[20px] font-bold text-black">
                      Curious about your hormonal health?
                    </h3>
                  </div>
                </div> */}

                <div className="space-y-3">
                  {/* At-Home Testing CTA */}
                  <Link
                    to="/test-comparison"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default navigation
                      handleNavigation("/test-comparison"); // Trigger navigation logic
                    }}
                    className="block p-4 bg-gradient-to-br from-[#F0AEAE] to-[#FBEBDD] flex flex-col items-start gap-3 border border-primary-foreground rounded-[14px] hover:shadow-custom transition-duration-200"
                  >
                    <div className="flex flex-col sm:flex-row gap-3 justify-between items-start md:items-center">
                      <div className="text-left">
                        <span className="block font-semibold text-[16px] md:text-[18px] text-black mb-1">
                          Compare At-Home Tests
                        </span>
                        <span className="text-[14px] md:text-[17px] text-black/90">
                          Explore hormone blood testing options from the comfort
                          of home
                        </span>
                      </div>
                      <div className="px-3 py-1 md:py-2 md:px-4 bg-primary-foreground items-center rounded-[12px] inline-flex gap-2 text-offWhite ">
                        <p className="text-[14px] md:text-[16px]">Explore</p>
                        <ChevronRight className="h-4 w-4 text-offWhite " />
                      </div>
                    </div>
                  </Link>

                  {/* GP Discussion */}
                  <div className="p-4 bg-offWhite rounded-[14px]">
                    <p className="text-left text-[16px] md:text-[17px] text-secondary">
                      If you choose to visit your GP, these are common tests
                      they might discuss:
                    </p>
                    <div className="mt-3 space-y-2 text-[14px] md:text-[16px] text-secondary">
                      <div className="flex items-center gap-2">
                        <div className="w-1 h-1 rounded-full bg-primary-foreground shrink-0" />
                        <span>Blood hormone levels</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="w-1 h-1 rounded-full bg-primary-foreground shrink-0" />
                        <span>Ultrasound examination</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="w-1 h-1 rounded-full bg-primary-foreground shrink-0" />
                        <span>Physical examination</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Community Support - Streamlined */}
              <div className="bg-[#FCF1E7] rounded-[24px] p-4 md:p-6 mt-4">
                {/* WhatsApp Community */}
                <div className="flex flex-wrap items-center gap-2 justify-between bg-white rounded-[14px] p-3 mb-4">
                  <div className="flex flex-wrap items-center gap-3">
                    <div className="p-2 bg-primary-foreground/5 rounded-[8px]">
                      <Users className="h-5 w-5 md:h-5 md:w-5 text-primary-foreground" />
                    </div>
                    <div className="text-left">
                      <h4 className="font-medium text-[16px] md:text-[18px] text-secondary">
                        Join Our Community
                      </h4>
                      <p className="text-[15px] md:text-[17px] text-secondary/70">
                        Get support & join events
                      </p>
                    </div>
                  </div>
                  <a
                    href="https://chat.whatsapp.com/LkC4VbRmgls6hmWnldM6JN"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 px-3 py-1.5 bg-primary-foreground text-white rounded-[12px] hover:bg-primary-foreground/90 transition-all duration-200"
                    aria-label="Join WhatsApp community"
                  >
                    <span className="text-[13px] font-medium">Join</span>
                    <ChevronRight className="h-4 w-4" />
                  </a>
                </div>

                {/* Social Links */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 w-full">
                  {[
                    {
                      href: "https://www.instagram.com/paiapcos/",
                      platform: "Instagram",
                      icon: <Instagram className="h-4 w-4" />,
                    },
                    {
                      href: "https://www.tiktok.com/@paiapcos",
                      platform: "TikTok",
                      icon: <Video className="h-4 w-4" />,
                    },
                  ].map((social) => (
                    <a
                      key={social.platform}
                      href={social.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-2 p-3 bg-white rounded-[14px] hover:shadow-custom transition-all duration-200"
                      aria-label={`Follow on ${social.platform}`}
                    >
                      <div className="p-1.5 bg-primary-foreground/5 rounded-[8px]">
                        {social.icon}
                      </div>
                      <span className="font-medium text-[13px] md:text-[14px] text-secondary">
                        {social.platform}
                      </span>
                    </a>
                  ))}
                </div>
              </div>
            </>
          </CollapsibleSection>
        </div>

        {/* Supportive Footer */}
        <footer className="text-center mt-12 md:mt-20 space-y-4 px-4">
          <div className="bg-primary shadow-custom rounded-lg h-[0.1rem] mb-4"></div>
          <p className="text-md lg:text-lg text-black/80">
            This tool helps you understand potential patterns to discuss with
            healthcare providers. It does not diagnose PCOS or replace medical
            advice.
          </p>
          <div className="flex justify-center items-center gap-2 text-xs text-primary-foreground/90">
            <Heart className="h-4 w-4" />
            <span>Paia supports you in your health journey</span>
          </div>
        </footer>
        <Alert
          show={showAlert}
          message="You haven't downloaded your consultation guide yet. Your results may be lost if you leave."
          onConfirm={handleConfirmNavigation}
          onCancel={handleCancelNavigation}
        />
      </main>
    </div>
  );
};

export default ResultsCard;
