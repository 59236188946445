import "./App.css";
import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ScrollToTop } from "./routes/ScrollToTop";


// Root Layout Routes
import Root from "./routes/Root";
import ErrorPage from "./routes/error-page";
import Landing from "./routes/Landing";
import ToolsPage from "./routes/Tools";
import HormoneTestsTable from "../../frontend/src/components/tools/compare/HormoneTestsTable";
import PrivacyPolicy from "./routes/PrivacyPolicy";

// Full Screen Layout Routes
import FullScreenLayout from "./routes/FullScreenLayout";
import PcosScreeningLanding from "./routes/PcosToolScreen";
import PcosDisclaimerScreen from "./routes/PcosToolDisclaimerScreen";
import PcosSymptomAssessment from "./components/tools/screening-tool/symptomChecker";

import About from "./routes/About";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ScrollToTop />
        <Root />
      </>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Landing />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "tools",
        element: <ToolsPage />,
      },
      {
        path: "test-comparison",
        element: <HormoneTestsTable />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy/>,
      },
    ],
  },
  {
    path: "/pcos-screening",
    element: <FullScreenLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <PcosScreeningLanding />,
      },
      {
        path: "disclaimer",
        element: <PcosDisclaimerScreen onStart={() => {}} />,
      },
      {
        path: "assessment",
        element: <PcosSymptomAssessment />,
      },
    ],
  },
]);

function App() {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export default App;
