import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { useViewport } from '../../hooks/useViewport';

interface AnimatedTextProps {
  customTextSize?: string;
  customMaxWidth?: string;
  customMarginY?: string;
}

const AnimatedText: React.FC<AnimatedTextProps> = ({
  customTextSize,
  customMaxWidth,
  customMarginY
}) => {
  const { viewportType } = useViewport();
  const textRef = useRef<HTMLDivElement>(null);
  const [isInView, setIsInView] = React.useState(false);

  const textSize = customTextSize || {
    mobile: 'text-[22px] leading-[1.4]',
    tablet: 'text-[28px] leading-[1.3]',
    desktop: 'text-[34px] leading-[1.2]'
  }[viewportType];

  const maxWidth = customMaxWidth || {
    mobile: 'max-w-[95%]',
    tablet: 'max-w-[85%]',
    desktop: 'max-w-[50%]'
  }[viewportType];

  const marginY = customMarginY || {
    mobile: 'mt-12 mb-8',
    tablet: 'mt-20 mb-12',
    desktop: 'mt-40 mb-16'
  }[viewportType];

  const phrases = [
    { text: "At Paia, ", className: "font-medium" },
    { text: "we're focusing on ", className: "font-medium" },
    { text: "PCOS awareness, ", className: "font-bold text-black" },
    { text: "accelerating diagnosis, ", className: "font-bold text-black" },
    { text: "guiding treatment, ", className: "font-bold text-black" },
    { text: "and ", className: "font-medium" },
    { text: "preventing associated risks.", className: "font-bold text-black" }
  ];

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.15,
        delayChildren: 0.2,
        when: "beforeChildren"
      }
    }
  };

  const phraseVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  React.useEffect(() => {
    const options = {
      root: null,
      rootMargin: '-10% 0px',
      threshold: 0.1
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    const currentRef = textRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="w-full flex justify-center items-center px-4">
      <motion.div
        ref={textRef}
        variants={containerVariants}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        className={`
          font-urbanist
          ${textSize}
          text-center
          ${marginY}
          ${maxWidth}
          mx-auto
          transition-all
          duration-300
          flex
          flex-wrap
          justify-center
          items-center
          gap-x-[0.35em]
        `}
      >
        {phrases.map((phrase, index) => (
          <motion.span
            key={index}
            variants={phraseVariants}
            className={`${phrase.className} whitespace-nowrap`}
          >
            {phrase.text}
          </motion.span>
        ))}
      </motion.div>
    </div>
  );
};

export default AnimatedText;