import React, { useState } from "react";
import paia from "../../assets/icons/paia.png";
import { Link } from "react-router-dom";
import Banner from "./Banner";
import AnimatedHamburgerBtn from "../buttons/AnimatedHamburgerBtn";
import { useNavigate } from "react-router-dom";

interface NavbarProps {
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMenuOpen: boolean;
}

const Navbar: React.FC<NavbarProps> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const menu = document.getElementById("mobile-menu");
    const button = document.getElementById("hamburger-btn");
    if (
      menu &&
      !menu.contains(event.target as Node) &&
      button &&
      !button.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleScrollToSection = (sectionId: string) => {
    navigate("/"); // Navigate to the home page

    // Use setTimeout to ensure the navigation completes first
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        setIsOpen(false); // Close mobile menu if open
      }
    }, 0); // Delay the scroll until the page renders fully
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* <Banner /> */}
      <nav className="fixed top-0 w-full bg-[#FCF1E7] z-40 flex items-end justify-center md:pb-4 px-4 md:px-8 lg:px-32 h-[7.75rem] shadow-sm">
        <div className="flex flex-row items-end w-full max-w-screen-2xl">
          <div className="hidden md:block pt-4">
            <Link to="/">
              <img
                src={paia}
                alt="Paia Logo"
                className="w-[4rem] md:w-[6.5rem] lg:w-[9rem] h-auto object-contain transition-all duration-300"
              />
            </Link>
          </div>

          <div className="hidden md:flex items-center gap-3 lg:gap-4 ml-auto">
            <Link to="/">
              <button
                className="text-black font-medium hover:bg-[#A26632] hover:text-[#FCF1E7] px-4 py-2 rounded-[30px] transition-all duration-300
                     text-[16px] md:text-[18px] lg:text-[24px] whitespace-nowrap"
                aria-label="Navigate to Home"
              >
                Home
              </button>
            </Link>
            <Link to="/about">
              <button
                className="text-black font-medium hover:bg-[#A26632] hover:text-[#FCF1E7] px-4 py-2 rounded-[30px] transition-all duration-300
                     text-[16px] md:text-[18px] lg:text-[24px] whitespace-nowrap"
                aria-label="Navigate to about us"
              >
                About Us
              </button>
            </Link>
            <Link to="/tools">
              <button
                className="text-[#FCF1E7] font-medium bg-secondary hover:text-[#FCF1E7] px-4 py-2 rounded-[30px] transition-all duration-300
                     text-[16px] md:text-[18px] lg:text-[24px] whitespace-nowrap"
                aria-label="Navigate to tools page"
              >
                Learn More
              </button>
            </Link>
          </div>

          <div className="md:hidden flex justify-between items-center w-full">
            <div className="pb-[0.5rem]">
              <Link to="/">
                <img src={paia} alt="Paia Logo" className="h-[4rem] w-auto" />
              </Link>
            </div>

            <div className="mr-4">
              <div
                onClick={toggleMenu}
                aria-label="Toggle menu"
                role="button"
                id="hamburger-btn"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === "Enter") toggleMenu();
                }}
              >
                <AnimatedHamburgerBtn setIsOpen={setIsOpen} isOpen={isOpen} />
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          id="mobile-menu"
          className={`fixed top-[7.5rem] right-0 h-full w-64 bg-[#FCF1E7] transform transition-transform duration-300 ease-in-out md:hidden z-50 ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="flex flex-col items-end space-y-8 pt-16 px-4">
            {/* Menu Links */}
            <Link onClick={() => setIsOpen(false)} to="/">
              <button
                className="block text-[24px] text-black font-medium hover:bg-[#A26632] hover:text-[#FCF1E7] px-4 py-2 rounded-[30px]"
                aria-label="Navigate to Events"
              >
                Home
              </button>
            </Link>
            <Link onClick={() => setIsOpen(false)} to="/about">
              <button
                className="block text-[24px] text-black font-medium hover:bg-[#A26632] hover:text-[#FCF1E7] px-4 py-2 rounded-[30px]"
                aria-label="Navigate to About Us"
              >
                About Us
              </button>
            </Link>
            <Link
              to="/tools"
              className="block text-[#FCF1E7] text-[24px] font-medium bg-secondary hover:text-[#FCF1E7] px-4 py-2 rounded-[30px] transition"
              onClick={() => setIsOpen(false)}
              aria-label="Navigate to tools"
            >
              Learn More
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
