import React, { ReactNode, useState, useEffect } from "react";
import { motion, useInView, AnimatePresence } from "framer-motion";
import Team from "../components/about/Team";
import Events from "../components/about/Events";
import scroll from "../assets/icons/scrollUp.png";

interface StoryParagraphProps {
  text: ReactNode;
  delay: number;
  className?: string;
}

const ScrollToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Track scroll position
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  // Smooth scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.button
          onClick={scrollToTop}
          className="fixed bottom-16 right-10 lg:bottom-32 lg:right-16 z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          aria-label="Scroll to top"
          style={{
            willChange: "transform",
            transform: "translateZ(0)",
            backfaceVisibility: "hidden",
          }}
        >
          <img
            src={scroll}
            alt="Scroll to top"
            className="w-auto h-[3.4rem] lg:h-[4.8rem]"
            style={{
              transform: "translateZ(0)",
              willChange: "transform",
            }}
          />
        </motion.button>
      )}
    </AnimatePresence>
  );
};

const StoryParagraph: React.FC<StoryParagraphProps> = ({
  text,
  delay,
  className = "",
}) => {
  const paragraphRef = React.useRef(null);
  const isInView = useInView(paragraphRef, {
    once: true,
    margin: "-50px",
  });

  return (
    <motion.p
      ref={paragraphRef}
      initial={{ opacity: 0 }}
      animate={isInView ? { opacity: 1 } : { opacity: 0 }}
      transition={{
        duration: 0.8,
        delay,
      }}
      className={`text-[20px] md:text-[24px] ${className}`}
      role="paragraph"
    >
      {text}
    </motion.p>
  );
};

const About: React.FC = () => {
  // Longer delay between paragraphs for better readability
  const getStaggeredDelay = (index: number) => {
    return 0.2 + index * 0.8; // Increased delay between paragraphs
  };

  const storyContent = [
    {
      text: <span className="font-bold italic">Maham's story:</span>,
      type: "heading",
    },
    {
      text: (
        <>
          My journey began in 2018 when I noticed my periods were{" "}
          <span className="font-semibold">inconsistent</span>. After enduring{" "}
          <span className="font-semibold">several misdiagnoses</span> and{" "}
          <span className="font-semibold">long waits </span>
          through the NHS, I started doing my own research. It was only through
          the privilege of accessing private healthcare that I was finally
          diagnosed with PCOS. This experience made me realise how many, like
          myself, often feel <span className="font-semibold">
            stigmatised
          </span>{" "}
          and isolated, especially without the same resources.
        </>
      ),
      type: "paragraph",
    },
    {
      text: (
        <>
          <span className="font-semibold">Managing</span> PCOS was another{" "}
          <span className="font-semibold">challenge</span>—Struggling to find
          truly{" "}
          <span className="font-semibold">supportive, dietitian-backed</span>{" "}
          food options, I realised the need for reliable{" "}
          <span className="font-semibold">PCOS-friendly </span>
          choices—sparking the inspiration for Paia.
        </>
      ),
      type: "paragraph",
    },
    {
      text: (
        <>
          Inspired by this, co-founders Sabihah, Srezon, Marah, and I developed{" "}
          <span className="font-semibold italic">Paia</span>. We are an app that{" "}
          <span className="font-semibold">empowers individuals</span> to take{" "}
          <span className="font-semibold">control of their health</span> through{" "}
          <span className="font-semibold">
            personalised, nutritionist & dietitian
          </span>{" "}
          backed diet recommendation, tailored to their goals.
        </>
      ),
      type: "paragraph",
    },
  ];

  return (
    <main
      id="about"
      className="w-screen min-h-screen shadow-lg pt-24 lg:pt-32 relative overflow-hidden"
    >
      {/* Background blur */}
      <div className="absolute inset-0 pointer-events-none">
        <div
          className="hidden md:block absolute"
          style={{
            width: "950px",
            height: "800px",
            top: "400px",
            left: "700px",
            background: "#FEE8DA",
            borderRadius: "50%",
            transform: "translate(-50%, -50%)",
            filter: "blur(40px)",
            zIndex: 1,
          }}
        />

        <div
          className="md:hidden absolute right-20 top-60"
          style={{
            width: "min(60vh, 500px)",
            height: "min(60vh, 500px)",
            background: "#FEE8DA",
            borderRadius: "50%",
            transform: "translate(25%, -25%)",
            filter: "blur(40px)",
          }}
        />
      </div>

      {/* Content container */}
      <div className="relative mx-auto py-8 sm:py-12 px-4 sm:px-6 lg:px-20 w-full sm:w-[90%] md:w-[85%] z-10">
        <header>
          <h1 className="text-primary-foreground font-bold font-hand text-[42px] md:text-[42px] py-6">
            About Us
          </h1>
        </header>

        <article
          className="flex flex-col space-y-12 sm:space-y-16"
          aria-label="Maham's PCOS journey and founding story"
        >
          {storyContent.map((content, index) => (
            <StoryParagraph
              key={index}
              text={content.text}
              delay={getStaggeredDelay(index)}
              className={content.type === "heading" ? "font-bold italic" : ""}
            />
          ))}
        </article>
      </div>

      {/* Team and Events sections */}
      <div className="relative z-10">
        <Team />
        <Events />
      </div>
      <ScrollToTop />
    </main>
  );
};

export default About;
