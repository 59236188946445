import React from "react";
import { Link } from "react-router-dom";

const Events = () => {

  return (
    <section id="events" className="col-span-6 mt-16 scroll-mt-[124px] max-w-screen-2xl mx-auto bg-[#9B3B3B] py-16">
      <h1
        className="text-[32px] lg:text-[38px] font-bold text-center text-muted-foreground"
      >
        Events
      </h1>
      <div className=" mx-8 md:mx-12 lg:mx-44">
        <div className=" mt-8">
          <p className="mb-4 text-offWhite text-left text-[18px] lg:text-[24px] font-medium">
            At Paia, we believe in fostering a supportive community to help
            navigate the challenges of PCOS. We understand that this journey can
            be difficult, which is why we're excited to offer a range of events
            designed to provide support and connection.
          </p>
        </div>
        <div className="flex flex-col space-y-4">
          <h2 className=" text-left font-semibold italic underline mt-8 text-muted-foreground text-[28px] lg:text-[34px]">
            Recent events
          </h2>
          <h3 className="text-primary-light text-[18px] font-bold lg:text-[26px]">
            Paia PCOS Walkathon - 28th September 2024
          </h3>
          <p className="text-[16px] lg:text-[24px] text-offWhite font-medium">
            Our Paia PCOS Walkathon brought together an incredible community to
            raise awareness and support those battling PCOS!
          </p>
        </div>
        <div className="my-6">
          <Link to="https://www.instagram.com/paiapcos/" target="_blank">
            <button
              aria-label="future events"
              className="bg-muted text-black w-fit rounded-[33px] lg:text-[17px] lg:text-[21px] font-regular hover:bg-muted/70 transition duration-200 py-2 px-8"
            >
              Find out about future events
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Events;
