import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  HelpCircle,
  CheckCircle,
  ArrowLeft,
  ArrowRight,
  Image as ImageIcon,
} from "lucide-react";
import ImageModal from "./ImageModal";


interface ReferenceImage {
  url: string;
  caption: string;
  source: string;
  description: string;
}
interface LicensedImage extends ReferenceImage {
  license: {
    name: string;
    url: string;
    terms: string[];
  };
  originalSource: {
    name: string;
    url: string;
  };
}

interface Option {
  text: string;
  score: number;
  explanation?: string;
  referenceImage?: ReferenceImage;
}

interface Question {
  key: string;
  text: string;
  description?: string;
  options: Option[];
  whyThisMatters: string;
  references: Array<{ label: string; url: string }>;
  resultPositive?: string;
  resultNegative?: string;
  referenceImage?: LicensedImage;
}

interface QuestionCardProps {
  question: Question;
  selectedOption: Option | null;
  onOptionSelect: (option: Option) => void;
  onNext: () => void;
  onPrevious: () => void;
  showPrevious: boolean;
  showInfo: boolean;
  onToggleInfo: () => void;
  isLastQuestion: boolean;
  canProceed: boolean;
}


const QuestionCard: React.FC<QuestionCardProps> = ({
  question,
  selectedOption,
  onOptionSelect,
  onNext,
  onPrevious,
  showPrevious,
  showInfo,
  onToggleInfo,
  isLastQuestion,
  canProceed,
}) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const isHirsutismQuestion = question.key === "Hirsutism";

  return (
    <div className="bg-white rounded-xl shadow-custom">
      <div className="p-4 sm:p-6 space-y-6">
        {/* Question Header */}
        <div className="text-center space-y-3">
          <h2 className="text-xl sm:text-2xl font-bold text-secondary leading-tight">
            {question.text}
          </h2>
          {/* Description - New Addition */}
          {question.description && (
            <motion.div 
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="mt-3 px-4 py-3 bg-primary/5 rounded-lg text-sm text-secondary/80 max-w-2xl mx-auto"
            >
              {question.description}
            </motion.div>
          )}
        </div>

        {/* Ferriman-Gallwey Score Reference Button */}
        {isHirsutismQuestion && question.referenceImage && (
            <button
              onClick={() => setShowImageModal(true)}
              className="mt-4 inline-flex items-center gap-2 px-4 py-2 
                       bg-primary/5 hover:bg-primary/10 rounded-lg
                       transition-all duration-200 text-sm font-medium
                       text-secondary border border-primary/20"
            >
              <ImageIcon className="h-4 w-4 text-primary-foreground" />
              View Ferriman-Gallwey Score Chart
            </button>
          )}

        {/* Options */}
        <div className="grid gap-3 sm:gap-4">
          {question.options.map((option, index) => (
            <motion.button
              key={index}
              onClick={() => onOptionSelect(option)}
              className={`w-full p-4 sm:p-5 text-left rounded-xl transition-colors duration-200
                ${
                  selectedOption === option
                    ? "bg-primary-foreground/10 border-2 border-primary-foreground"
                    : "bg-white hover:bg-primary-foreground/5 border border-border"
                }
                focus:outline-none focus:ring-1 focus:ring-primary-foreground focus:ring-offset-1`}
              aria-selected={selectedOption === option}
              role="option"
            >
              <div className="flex items-center justify-between gap-4">
                <span className="font-medium text-secondary">
                  {option.text}
                </span>
                {selectedOption === option && (
                  <div className="flex items-center gap-2">
                    {option.referenceImage && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowImageModal(true);
                        }}
                        className="p-1.5 rounded-full bg-primary-foreground/10 
                                 hover:bg-primary-foreground/20 transition-colors duration-200"
                        aria-label="View reference image"
                      >
                        <ImageIcon className="h-4 w-4 text-primary-foreground" />
                      </button>
                    )}
                    <CheckCircle className="h-5 w-5 text-primary-foreground" />
                  </div>
                )}
              </div>
            </motion.button>
          ))}
        </div>

        {/* Navigation */}
        <div className="flex flex-col sm:flex-row gap-3">
          {showPrevious && (
            <button
              onClick={onPrevious}
              className="w-full sm:w-auto order-2 sm:order-1 px-6 py-3 
                       border border-border text-secondary font-medium 
                       rounded-lg hover:bg-primary-foreground/20 transition-colors duration-200
                       flex items-center justify-center gap-2"
              aria-label="Go to previous question"
            >
              <ArrowLeft className="h-4 w-4" />
              <span>Previous</span>
            </button>
          )}
          <button
            onClick={onNext}
            disabled={!canProceed}
            className={`w-full sm:flex-1 order-1 sm:order-2 px-6 py-3 
                     font-medium rounded-lg transition-colors duration-200
                     flex items-center justify-center gap-2
                     ${
                       canProceed
                         ? "bg-primary-foreground text-offWhite hover:bg-primary-foreground"
                         : "bg-muted/90 text-secondary/40 cursor-not-allowed"
                     }`}
          >
            <span>{isLastQuestion ? "View Your Results" : "Continue"}</span>
            <ArrowRight className="h-4 w-4" />
          </button>
        </div>

        {/* Learn More Badge */}
        <div className="flex justify-center">
          <button
            onClick={onToggleInfo}
            className="inline-flex items-center gap-2 px-4 py-2 
                     bg-primary-foreground/5 hover:bg-primary-foreground/10 rounded-full
                     transition-colors duration-200 group"
            aria-expanded={showInfo}
          >
            <HelpCircle className="h-5 w-5 text-primary-foreground" />
            <span className="text-sm font-medium text-secondary">
              Learn more about this symptom
            </span>
          </button>
        </div>
      </div>

      {/* Learn More Content - Fixed height container */}
      <AnimatePresence>
        {showInfo && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="border-t border-border/20"
          >
            <div className="p-4 sm:p-6 bg-primary/5 space-y-3">
              <p className="text-sm text-secondary leading-relaxed">
                {question.whyThisMatters}
              </p>
              <div className="space-y-2">
                {question.references.map((ref, index) => (
                  <a
                    key={index}
                    href={ref.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-primary-foreground hover:underline block"
                  >
                    {ref.label}
                  </a>
                ))}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showImageModal && question.referenceImage && (
          <ImageModal
            image={question.referenceImage as LicensedImage}
            onClose={() => setShowImageModal(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default QuestionCard;
