import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {  ChevronLeft, ArrowRight, ShieldCheck, Clock, Heart } from "lucide-react";
import logo from "../assets/icons/paia.png";
import doctorImg1 from "../assets/images/osama.jpg";
import doctorImg2 from "../assets/images/zahra.jpg";
import Feature from "../components/tools/screening-tool/Feature";
import ExpertCard from "../components/tools/screening-tool/ExpertCard";

const ScreeningLanding: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen bg-background">
       <nav className="sticky top-0 p-4 bg-background/95 backdrop-blur-sm z-20 border-b border-primary/10">
        <div className="max-w-2xl mx-auto flex justify-between items-center">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center px-3 py-2 text-secondary hover:text-primary-foreground transition-all duration-300"
            aria-label="Go back"
          >
            <ChevronLeft className="h-4 w-4 mr-2" />
            <span className="text-sm">Back</span>
          </button>
          <img src={logo} alt="Paia Logo" className="h-8" />
        </div>
      </nav>

      <main className="container mx-auto px-4 sm:px-6 pt-10 pb-12">
        <div className="max-w-4xl mx-auto space-y-8 sm:space-y-12">
          {/* Hero Section */}
          <div className="text-center space-y-4 sm:space-y-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="space-y-4 sm:space-y-6"
            >
              <div className="inline-flex items-center px-3 py-1 bg-primary/10 rounded-full">
                <Heart className="h-4 w-4 text-primary-foreground mr-2" />
                <span className="text-xs font-medium text-secondary">
                  Educational Tool
                </span>
              </div>

              <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-secondary">
                Understand PCOS Symptoms
              </h1>

              <p className="text-base sm:text-lg text-secondary/90 max-w-xl mx-auto">
                Take our quick assessment to learn about common PCOS symptoms and
                get a personalised guide to <span className="font-bold">advocate for your health </span>and have
                more informed discussions with your healthcare provider.
              </p>
            </motion.div>
          </div>

          {/* Main Content */}
          <div className="grid md:grid-cols-2 gap-8">
            {/* Left Column */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="space-y-4"
            >
              <Feature
                icon={Clock}
                title="Quick Assessment"
                description="2-3 minutes to complete"
              />
              <Feature
                icon={ShieldCheck}
                title="Evidence-Based"
                description="Following international PCOS guidelines"
              />
              <Feature
                icon={Heart}
                title="Personalised Guide"
                description="Get guidance to discuss with your healthcare provider"
              />
            </motion.div>

            {/* Right Column */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              className="bg-gradient-to-br from-[#FCF1E7] to-[#F8D3C4] rounded-[20px] p-6 shadow-custom border border-primary w-full"
            >
              <div className="space-y-6">
                <div className="rounded-xl">
                  <h2 className="text-[28px] md:text-[34px] font-semibold text-secondary mb-2">
                    What You'll Learn
                  </h2>
                  <ul className="space-y-3 bg-[#FFFBF8CC]/80 border border-primary p-3 rounded-[20px]">
                    {[
                      "Identify which symptoms may warrant further medical attention",
                      "Understand how to effectively communicate your concerns with your healthcare provider",
                      "Learn what tests are needed for a PCOS diagnosis",
                    ].map((point, index) => (
                      <li
                        key={index}
                        className="flex items-center gap-2 text-secondary/70"
                      >
                        <div className="h-[0.15rem] w-[0.15rem] rounded-full bg-black" />
                        <span className="text-sm text-black font-light">
                          {point}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="text-[18px] md:text-[22px] font-light text-secondary">
                Consider your symptoms and experiences over the past 6-12 months for accurate responses
                </div>
              </div>
            </motion.div>
          </div>

          {/* Expert Section */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="space-y-6"
          >
            <div className="text-center space-y-2">
              <h2 className="text-xl font-semibold text-secondary">
                Reviewed by Leading PCOS Specialists
              </h2>
              <p className="text-sm text-secondary/70">
                Expert guidance from NHS consultant gynaecologists with
                extensive expertise in PCOS
              </p>
            </div>

            <div className="grid sm:grid-cols-2 gap-6">
              <ExpertCard
                name="Dr. Osama Abughazza"
                credentials="MD, MRCOG, MSc"
                role="Consultant Gynaecologist"
                image={doctorImg1}
                linkedin="https://uk.linkedin.com/in/osama-abu-ghazza-442083b2"
              />
              <ExpertCard
                name="Dr. Zahra Ameen"
                credentials="MBBS BSc MRCOG"
                role="Consultant Gynaecologist"
                image={doctorImg2}
                linkedin="https://uk.linkedin.com/in/zahra-ameen-ba788292"
              />
            </div>
          </motion.section>

          {/* CTA */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center space-y-4"
          >
            <Link
              to="/pcos-screening/disclaimer"
              className="inline-flex items-center justify-center px-8 py-3 
                     bg-primary-foreground text-offWhite font-medium rounded-xl
                     hover:bg-primary-foreground/60 transition-colors duration-300
                     shadow-custom hover:shadow-lg group"
            >
              Take me to the assessment
              <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform" />
            </Link>
          </motion.div>
        </div>
      </main>
    </div>
  );
};

export default ScreeningLanding;