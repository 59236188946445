import React from "react";

interface FeatureProps {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
}

const Feature: React.FC<FeatureProps> = ({
  icon: Icon,
  title,
  description,
}) => (
  <div className="flex items-center gap-4 bg-white/50 p-4 rounded-xl">
    <div className="w-10 h-10 rounded-xl bg-primary/10 flex items-center justify-center">
      <Icon className="h-5 w-5 text-primary-foreground" />
    </div>
    <div>
      <h3 className="font-medium text-secondary">{title}</h3>
      <p className="text-sm text-secondary/70">{description}</p>
    </div>
  </div>
);

export default Feature