import React, { useState, useEffect } from "react";
import DesktopHero from "./hero/DesktopHero";
import TabletHero from "./hero/TabletHero";
import MobileHero from "./hero/MobileHero";
import { useViewport, ViewportType } from "../../hooks/useViewport";

interface FormData {
  name: string;
  email: string;
}

// Define shared props interface for consistency across components
interface SharedProps {
  formData: FormData;
  isLoading: boolean;
  isSubmitted: boolean;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleScrollToSection: (sectionId: string) => void;
  textAnimationVariants: any; // Consider defining a more specific type if needed
  mounted: boolean;
}

const Hero: React.FC = () => {
  const { viewportType } = useViewport();
  const [formData, setFormData] = useState<FormData>({ name: "", email: "" });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  // Define animation variants
  const textAnimationVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: (custom: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        delay: custom * 0.15,
        ease: [0.25, 0.1, 0.25, 1],
      },
    }),
  };

  const handleScrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.requestAnimationFrame(() => {
        const sectionTop = section.offsetTop;
        window.scrollTo({
          top: sectionTop,
          behavior: "smooth",
        });
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value.trim() }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.email || !formData.name) return;

    setIsLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsSubmitted(true);
      setFormData({ name: "", email: "" });
    } catch (error) {
      console.error("Submission error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const sharedProps: SharedProps = {
    formData,
    isLoading,
    isSubmitted,
    handleSubmit,
    handleInputChange,
    handleScrollToSection,
    textAnimationVariants,
    mounted,
  };

  const renderHero = () => {
    switch (viewportType) {
      case 'mobile':
        return <MobileHero {...sharedProps} />;
      case 'tablet':
        return <TabletHero {...sharedProps} />;
      case 'desktop':
        return <DesktopHero {...sharedProps} />;
      default:
        return <DesktopHero {...sharedProps} />; // Fallback to desktop
    }
  };

  return renderHero();
};

export default Hero;