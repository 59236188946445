import React from 'react';
import { ChevronLeft, ExternalLink } from 'lucide-react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  const Section: React.FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
    <div className="mb-8 last:mb-0">
      <h2 className="text-[20px] md:text-[24px] font-bold text-secondary mb-4">{title}</h2>
      <div className="space-y-4 text-secondary/80">{children}</div>
    </div>
  );

  return (
    <div className="min-h-screen mt-32 bg-background">
      <main className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-offWhite rounded-[34px] shadow-custom p-6 md:p-12">
          {/* Title Section */}
          <div className="text-center mb-8">
            <h1 className="text-[24px] md:text-[32px] font-bold text-black mb-4">Privacy Policy</h1>
            <p className="text-[14px] md:text-[16px] text-secondary/80">
              Last updated: {new Date().toLocaleDateString()}
            </p>
          </div>

          {/* Introduction */}
          <Section title="Our Commitment to Your Privacy">
            <p>
              At Paia PCOS, we take your privacy seriously. This policy explains how we collect,
              use, and protect your information when you use our services, particularly focusing
              on our email communications and health assessment tools.
            </p>
          </Section>

          {/* Information Collection */}
          <Section title="Information We Collect">
            <p>We collect the following information:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Email address (for consultation guide delivery and optional marketing)</li>
              <li>Health assessment responses (stored anonymously)</li>
              <li>Marketing preferences (opt-in choices for updates and events)</li>
            </ul>
          </Section>

          {/* How We Use Information */}
          <Section title="How We Use Your Information">
            <p>Your information is used for:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Delivering your consultation guide and assessment results</li>
              <li>Sending follow-up resources and educational content (if opted in)</li>
              <li>Informing you about relevant events and updates (if opted in)</li>
              <li>Improving our health assessment tools and services</li>
            </ul>
          </Section>

          {/* Children's Privacy - New Section */}
          <Section title="Children's Privacy">
            <p>Our services, including the screening tool, are designed for users of all ages, including individuals aged 15 or younger:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li><span className="font-semibold">Parental/guardian consent:</span> We recommend that individuals under 16 obtain parental or guardian consent before using the screening tool.</li>
              <li><span className="font-semibold">Data protection:</span> Any health assessment responses from users aged 15 or younger are stored anonymously to ensure privacy and compliance with legal standards.</li>
              <li><span className="font-semibold">Educational purpose:</span> The tool is provided for informational and educational purposes and is not a substitute for professional medical advice.</li>
            </ul>
          </Section>

          {/* Cookies Section - New */}
          <Section title="Cookies and Tracking Technologies">
            <p>We may use cookies or similar technologies on our website to enhance your experience. Cookies such as persistent cookies may be used for:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li><span className="font-semibold">Analytics:</span> Understanding how users interact with our site to improve our services.</li>
              <li><span className="font-semibold">Preferences:</span> Remembering your preferences for smoother navigation.</li>
            </ul>
            <p className="mt-4">You can adjust your cookie preferences in your browser settings.</p>
          </Section>

          {/* Third-Party Services - New */}
          <Section title="Third-Party Services">
            <p>We use trusted third-party services to assist with email delivery, analytics, and data storage. These services comply with U.K. GDPR and are contractually obligated to safeguard your information.</p>
            <p className="mt-4">We confirm no selling of personal data and limited sharing strictly for operational needs.</p>
          </Section>

          {/* Regulatory Compliance - New */}
          <Section title="Regulatory Compliance">
            <p>This Privacy Policy complies with the U.K. GDPR and the Data Protection Act 2018. If you have unresolved concerns, you can lodge a complaint with the Information Commissioner's Office (ICO):</p>
            <a 
              href="https://ico.org.uk" 
              target="_blank" 
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 text-primary-foreground hover:text-primary transition-colors duration-200 mt-2"
            >
              Visit ICO Website <ExternalLink className="h-4 w-4" />
            </a>
          </Section>

          {/* Data Protection */}
          <Section title="Data Protection">
            <ul className="list-disc pl-6 space-y-2">
              <li>Your health assessment data is stored anonymously</li>
              <li>We use industry-standard security measures to protect your information</li>
              <li>We never share or sell your personal information to third parties</li>
              <li>Your email is only used for the purposes you've explicitly agreed to</li>
            </ul>
          </Section>

          {/* Your Rights */}
          <Section title="Your Rights">
            <p>You have the right to:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Access your personal information</li>
              <li>Update your communication preferences</li>
              <li>Request deletion of your data</li>
              <li>Opt-out of marketing communications at any time</li>
            </ul>
          </Section>

          {/* Contact Section */}
          <div className="mt-12 p-6 bg-primary/5 rounded-[24px] text-center">
            <h2 className="text-[18px] md:text-[22px] font-bold text-secondary mb-4">
              Questions About Your Privacy?
            </h2>
            <p className="text-[14px] md:text-[16px] text-secondary/80 mb-4">
              We're here to help if you have any questions about your privacy or our data practices.
            </p>
            <a
              href="mailto:hello@paiapcos.com"
              className="inline-flex items-center gap-2 px-6 py-3 bg-primary-foreground text-white rounded-[30px] hover:bg-primary-foreground/90 transition-colors duration-200"
            >
              Contact Us
            </a>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PrivacyPolicy;