export const pcosToolData = [
  {
    key: "Age",
    text: "How old are you?",
    options: [
      { text: "15 or younger", score: 0 },
      { text: "16-25", score: 3 },
      { text: "26-35", score: 2 },
      { text: "36 or older", score: 0 },
    ],
    whyThisMatters:
      "PCOS symptoms commonly appear during late teens or early twenties, making age a factor in symptom onset.",
    references: [
      { label: "NICE Guidelines", url: "https://www.nice.org.uk/guidance" },
      { label: "Monash Guidelines", url: "https://www.monash.edu/pcos" },
    ],
    resultPositive:
      "You are within an age range where PCOS symptoms are more likely to manifest, especially in late teens to mid-twenties.",
    resultNegative:
      "Your age suggests PCOS symptoms are less likely to have newly developed, but symptoms can persist regardless of age.",
  },
  {
    key: "Irregular periods",
    text: "If you are over 3 years past menarche (your first period), how many days are there between the first day of your period and the first day of your next period?",
    description:
      "Natural periods means you are not using hormonal contraception and are not in perimenopause/menopause. If you're using hormonal contraception or experiencing perimenopause/menopause, please select the appropriate option below.",
    options: [
      { text: "21-35 days", score: 0 },
      { text: "Fewer than 21 days or more than 35 days", score: 2 },
      { text: "Very rarely or not at all", score: 3 },
      { text: "I'm not sure", score: 0 },
      {
        text: "I don't have natural periods (using hormonal contraception, or in perimenopause/menopause)",
        score: 0,
        explanation:
          "This option is for those using hormonal contraception or experiencing perimenopause/menopause changes.",
      },
    ],
    whyThisMatters:
      "Menstrual cycle irregularity is a hallmark of PCOS. Cycles shorter than 21 days or longer than 35 days, or infrequent cycles, can indicate difficulties with hormonal regulation, a key feature of PCOS.",
    references: [
      { label: "Monash Guidelines", url: "https://www.monash.edu/pcos" },
    ],
    resultPositive:
      "You reported experiencing irregular natural periods, which is a hallmark symptom of PCOS and suggests your body may be having difficulty regulating reproductive hormones.",
    resultNegative:
      "You have regular periods, are using hormonal contraception, experiencing perimenopause/menopause, or chose unsure.",
  },
  {
    key: "Hirsutism",
    text: "Do you notice thicker or darker hair in areas such as your upper lip, chin, chest, stomach, back, or thighs? See the Ferriman-Gallwey Score for examples.",
    options: [
      {
        text: "No, my hair growth in these areas is fine/minimal or matches my natural hair color (Score: 0-1)",
        score: 0,
      },
      {
        text: "Yes, I notice slightly thicker or darker hair, but it's mild and manageable (Score: 2-4)",
        score: 1,
      },
      {
        text: "Yes, I have noticeable thicker or darker hair growth in multiple areas (Score: 5-8)",
        score: 1,
      },
      {
        text: "Yes, I have significant thick or dark hair growth that I regularly manage (Score: 8+)",
        score: 1,
      },
    ],
    whyThisMatters:
      "Thicker or darker hair growth in these areas can be a sign of hirsutism, which is often caused by higher levels of androgens and is a common symptom of PCOS. Up to 70-80% of women with hirsutism may have PCOS. While hirsutism itself isn't harmful, it can indicate underlying hormonal regulation challenges that may require attention. Identifying and communicating these symptoms to your doctor can help with diagnosis and management.",
    references: [
      {
        label: "AE-PCOS Society Guidelines",
        url: "https://www.ae-society.org",
      },
    ],
    referenceImage: {
      url: "../../../assets/images/ferriman-gallwey-score.png",
      caption:
        "Ferriman-Gallwey Score Chart - A standardised method to assess hirsutism",
      source: "OpenMed",
      license: {
        name: "Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License",
        url: "https://creativecommons.org/licenses/by-nc-sa/4.0/",
        terms: [
          "Attribution required",
          "Non-commercial use only",
          "Share adaptations under the same license",
        ],
      },
      originalSource: {
        name: "OpenMed",
        url: "https://www.openmed.co.in/2021/09/ferriman-gallwey-scoring-system-for.html",
      },
      description: `This reference guide helps you understand different hair growth patterns across nine body areas. 
      Healthcare providers use this scale where each area is assessed from 0 (minimal hair) to 4 (more extensive growth). 
      This gives a standardized way to discuss hair growth patterns with your healthcare provider.
      
      Remember:
      • This is just a reference tool
      • Everyone's natural hair patterns are different
      • These images help provide a common language for discussion with healthcare providers
      • Your personal comfort level is what matters most`,
    },
    resultPositive:
      "You reported experiencing excess or darker hair growth in areas like the face or body, which could indicate your body is having difficulty regulating androgen levels, common in PCOS.",
    resultNegative:
      "You do not experience excess hair growth in these areas, suggesting androgen levels may not be elevated.",
  },
  {
    key: "Hair Thinning",
    text: "Have you noticed thinning hair or hair loss from your head for more than three months?",
    options: [
      { text: "No", score: 0 },
      { text: "Yes", score: 1 },
      { text: "I'm not sure.", score: 0 },
    ],
    whyThisMatters:
      "Hair thinning (androgenic alopecia) on the scalp is a symptom linked to how your body regulates androgens in PCOS. Persistent hair loss requires medical assessment.",
    references: [
      { label: "Monash Guidelines", url: "https://www.monash.edu/pcos" },
    ],
    resultPositive:
      "You reported hair thinning or scalp hair loss, which can occur when your body has difficulty regulating androgens, a common feature of PCOS.",
    resultNegative:
      "You do not report hair thinning or scalp hair loss, which is a good indicator that androgen excess may not be present.",
  },

  {
    key: "Acne",
    text: "Do you experience persistent or severe acne, even when using over-the-counter treatments?",
    options: [
      { text: "No", score: 0 },
      { text: "Yes", score: 1 },
    ],
    whyThisMatters:
      "Acne, especially on the jawline, chin, or back, can be a sign that your body is having difficulty regulating hormones. Persistent adult acne may indicate challenges with androgen regulation, which is common in PCOS.",
    references: [
      { label: "NICE Guidelines", url: "https://www.nice.org.uk/guidance" },
      { label: "Monash Guidelines", url: "https://www.monash.edu/pcos" },
    ],
    resultPositive:
      "You experience persistent acne, which is often related to how your body regulates hormones in PCOS.",
    resultNegative:
      "You do not experience persistent acne, which suggests androgen-related skin issues are less likely.",
  },
  {
    key: "Weight Gain",
    text: "Have you gained a significant amount of weight (e.g., more than 5% of your body weight) in a short period (e.g., six months) without explanation (e.g., changes in diet or exercise)?",
    options: [
      { text: "No", score: 0 },
      { text: "Yes", score: 1 },
      { text: "I'm not sure.", score: 0 },
    ],
    whyThisMatters:
      "Rapid or unexplained weight gain is common in PCOS and is often linked to insulin resistance. Weight changes can exacerbate PCOS symptoms and complications.",
    references: [
      { label: "NICE Guidelines", url: "https://www.nice.org.uk/guidance" },
      { label: "Monash Guidelines", url: "https://www.monash.edu/pcos" },
    ],
    resultPositive:
      "You reported experiencing significant or unexplained weight gain, which is often associated with insulin resistance in PCOS.",
    resultNegative:
      "You do not report unexplained weight gain, indicating insulin resistance may not be a primary concern.",
  },
  {
    key: "Insulin Resistance",
    text: "Have you ever been diagnosed with insulin resistance, prediabetes, or Type 2 diabetes?",
    options: [
      { text: "No", score: 0 },
      { text: "Yes", score: 1 },
    ],
    whyThisMatters:
      "Insulin resistance is a common feature of PCOS where the body has difficulty regulating both insulin and reproductive hormones. Women with PCOS have a higher risk of prediabetes and Type 2 diabetes, which require early management.",
    references: [
      { label: "Monash Guidelines", url: "https://www.monash.edu/pcos" },
    ],
    resultPositive:
      "You have been diagnosed with insulin resistance or related conditions, which are common in PCOS and require careful management.",
    resultNegative:
      "You do not have a diagnosis of insulin resistance or related conditions, which is reassuring but doesn't rule out metabolic factors.",
  },
  {
    key: "Sleep Issues",
    text: "Do you experience any of the following sleep disturbances?",
    options: [
      { text: "None", score: 0 },
      { text: "Snoring", score: 1 },
      { text: "Waking unrefreshed from sleep", score: 1 },
      { text: "Daytime sleepiness or fatigue", score: 1 },
    ],
    whyThisMatters:
      "Sleep disturbances, including obstructive sleep apnea, are more common in women with PCOS and may worsen symptoms like weight gain and hormonal imbalances.",
    references: [
      { label: "NICE Guidelines", url: "https://www.nice.org.uk/guidance" },
    ],
    resultPositive:
      "You experience sleep disturbances, which may exacerbate PCOS symptoms such as weight gain and hormonal imbalance.",
    resultNegative:
      "You do not report sleep disturbances, which is a positive indicator for overall metabolic and hormonal health.",
  },
  {
    key: "PCO/AMH",
    text: "Have you ever been told by a doctor that you have polycystic ovaries or an elevated Anti-Müllerian Hormone (AMH) level?",
    options: [
      { text: "No", score: 0 },
      { text: "Yes", score: 1 },
    ],
    whyThisMatters:
      "Polycystic ovaries or elevated AMH are diagnostic criteria for PCOS, per the Rotterdam guidelines. This question identifies critical diagnostic indicators.",
    references: [
      { label: "Monash Guidelines", url: "https://www.monash.edu/pcos" },
    ],
    resultPositive:
      "You have been told you have polycystic ovaries or elevated AMH, which are key diagnostic criteria for PCOS.",
    resultNegative:
      "You have not been told you have polycystic ovaries or elevated AMH.",
  },
  {
    key: "Subfertility",
    text: "Are you currently trying to get pregnant or have you experienced difficulties conceiving in the past?",
    options: [
      {
        text: "No, I am not trying to get pregnant and have not experienced difficulties conceiving.",
        score: 0,
      },
      { text: "Yes, I am trying to get pregnant", score: 1 },
      {
        text: "Yes, I have experienced difficulties conceiving in the past.",
        score: 1,
      },
    ],
    whyThisMatters:
      "PCOS is responsible for 70% of ovulation-related subfertility cases. Despite this, 80-85% of women with PCOS successfully conceive with treatment or lifestyle changes. This question helps users understand fertility concerns and explore.",
    references: [
      { label: "NICE Guidelines", url: "https://www.nice.org.uk/guidance" },
    ],
    resultPositive:
      "You reported experiencing difficulty conceiving, which is a common concern among women with PCOS due to irregular ovulation.",
    resultNegative:
      "You have not experienced difficulty conceiving, which suggests ovulation may not be significantly disrupted.",
  },
  {
    key: "Family History",
    text: "Do you have a family history of PCOS (mother, sister, aunt)?",
    options: [
      { text: "No", score: 0 },
      { text: "Yes", score: 1 },
      { text: "I'm not sure.", score: 0 },
    ],
    whyThisMatters:
      "Family history may indicate a genetic predisposition to PCOS, increasing the likelihood of its presence.",
    references: [
      { label: "Monash Guidelines", url: "https://www.monash.edu/pcos" },
    ],
    resultPositive:
      "You have a family history of PCOS, which increases your likelihood of having the condition due to its genetic component.",
    resultNegative:
      "You do not have a family history of PCOS, which may lower your likelihood of having the condition.",
  },
  {
    key: "Spotting",
    text: "Have you experienced spotting or light bleeding between periods that occurs regularly or without explanation?",
    options: [
      {
        text: "No, I have not experienced spotting or irregular bleeding.",
        score: 0,
      },
      { text: "Yes, occasionally.", score: 1 },
      { text: "Yes, frequently or persistently.", score: 1 },
      { text: "I'm not sure.", score: 0 },
    ],
    whyThisMatters:
      "Spotting may indicate hormonal imbalances or uterine abnormalities linked to PCOS. In rare cases, it could signal endometrial cancer. Early identification allows timely intervention.",
    references: [
      { label: "Monash Guidelines", url: "https://www.monash.edu/pcos" },
      { label: "NICE Guidelines", url: "https://www.nice.org.uk/guidance" },
    ],
    resultPositive:
      "You reported experiencing spotting or irregular bleeding, which can indicate hormonal imbalances or other PCOS-related concerns.",
    resultNegative:
      "You do not report spotting or irregular bleeding, which is a positive indicator of uterine health and hormonal balance.",
  },
];
