import React from "react";
import { useState, useEffect, useCallback } from "react";
import scrollUpImage from "../assets/icons/scrollUp.png";
import Hero from "../components/landing/Hero"
import HeroTwo from "../components/landing/HeroTwo";
import JoinCommunity from "../components/buttons/JoinCommunity";
import HowitWorks from "../components/landing/HowitWorks";
import { AnimatePresence, motion } from "framer-motion";

const Landing = () => {
  const [showScrollUpButton, setShowScrollUpButton] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState(false);

  // Throttled scroll handler
  const handleScroll = useCallback(() => {
    if (!isScrolling) {
      setIsScrolling(true);

      requestAnimationFrame(() => {
        const scrollPosition = window.scrollY;
        setShowScrollUpButton(scrollPosition > 500);
        setIsScrolling(false);
      });
    }
  }, [isScrolling]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="App" id="landing">
      <style>{`
      html {
        scroll-behavior: smooth;
      }
    `}</style>

      <div className="">
        <Hero />
        <HeroTwo/>
        <HowitWorks/>
        <JoinCommunity colour={'#9B3B3B'}/>
      </div>
      <AnimatePresence>
        {showScrollUpButton && (
          <motion.button
            onClick={scrollToTop}
            className="fixed bottom-16 right-10 lg:bottom-32 lg:right-16 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            aria-label="Scroll to top"
            style={{
              willChange: 'transform',
              transform: 'translateZ(0)',
              backfaceVisibility: 'hidden'
            }}
          >
            <img 
              src={scrollUpImage} 
              alt="Scroll to top" 
              className="w-auto h-[3.4rem] lg:h-[4.8rem]"
              style={{ 
                transform: 'translateZ(0)',
                willChange: 'transform'
              }} 
            />
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Landing;
