import { useState, useEffect } from 'react';

export type ViewportType = 'mobile' | 'tablet' | 'desktop';

interface ViewportState {
  viewportType: ViewportType;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const useViewport = (): ViewportState => {
  const [viewport, setViewport] = useState<ViewportState>({
    viewportType: 'desktop',
    isMobile: false,
    isTablet: false,
    isDesktop: true
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport({
          viewportType: 'mobile',
          isMobile: true,
          isTablet: false,
          isDesktop: false
        });
      } else if (width < 1024) {
        setViewport({
          viewportType: 'tablet',
          isMobile: false,
          isTablet: true,
          isDesktop: false
        });
      } else {
        setViewport({
          viewportType: 'desktop',
          isMobile: false,
          isTablet: false,
          isDesktop: true
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return viewport;
};