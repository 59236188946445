import React from "react";
import AppSignup from "../components/landing/AppSignup";
import ToolCard from "../components/tools/ToolCard";
import JoinCommunity from "../components/buttons/JoinCommunity";
import tube from "../assets/icons/test-tube.png"
import document from "../assets/icons/document.png"
import clock from "../assets/icons/clock.png"
import hand from "../assets/icons/hand.png"

const ToolsPage = () => {
  return (
    <div className=" mt-32 bg-background">
      <main className="container mx-auto px-6 py-16">
        {/* Hero Section */}
        <div className="max-w-2xl mx-auto mb-16 text-center space-y-6">
          
          <h1 className="text-4xl font-bold text-black">
            Get Clarity About Your Symptoms
          </h1>
        </div>

        {/* Main Tools */}
        <div className="max-w-5xl mx-auto mb-16">
          <div className="grid gap-4 sm:gap-6 md:grid-cols-2 max-w-[1200px] mx-auto">
            {/* Symptom Checker - Primary Tool */}
            <ToolCard
              title="PCOS Symptom Check"
              colour="#5C3411"
              colourTwo="#5C3411"
              description="Take our quick assessment to understand your symptoms and get a personalised GP consultation guide"
              linkText="Start Assessment"
              linkTo="/pcos-screening"
              icon={document}
              stats={[
                { value: "2 min", label: "Quick Check", image: clock},
                { value: "Free", label: "Consultation Guide", image: hand }
              ]}
              primary
            />
            
            {/* Test Comparison Tool */}
            <ToolCard
              title="Compare Home Testing Options"
              colour="#9B3B3B"
              description="Find and compare at-home hormone testing packages from trusted UK providers"
              linkText="View Comparison"
              linkTo="/test-comparison"
              icon={tube}
              stats={[
                { value: "10+", label: "Providers"},
                { value: "3-7 Days", label: "Results", image: clock }
              ]}
            />
          </div>
        </div>

        <div className="rounded-lg shadow-custom h-[0.15rem] md:h-[0.2rem] mx-auto bg-primary my-20 max-w-4xl"></div>
        {/* App Signup Section */}
        <div className="max-w-4xl mx-auto">
          <AppSignup />
        </div>
      </main>

      {/* Footer */}
      <footer className="border-t border-primary/80 mx-8 md:mx-32 mt-16 mb-12">
        <div className="max-w-7xl py-8">
          <p className="text-center text-[16px] md:text-[22px] text-black/60">
            For educational purposes only. Always consult healthcare professionals for medical advice.
          </p>
        </div>
      </footer>
      <JoinCommunity colour="#A26632"/>
    </div>
  );
};

export default ToolsPage;