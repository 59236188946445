import React, { useState, useEffect } from "react";
import { ExternalLink, Info, AlertCircle, X } from "lucide-react";
import QuickFilters from "./QuickFilters";
import MobileTestCard from "./MobileTestCard";
import { motion, AnimatePresence } from "framer-motion";
import scrollUpImage from "../../../assets/icons/scrollUp.png";
import BaseModal from "./BaseModal";
import DesktopTable from "./DesktopTable";
import TableLoading from "./TableLoading";

interface TestData {
  Company: string;
  Biomarkers: string;
  Results: string;
  Support: string;
  GP: boolean;
  Diagnosis: string;
  Price: number;
  Link: string;
}

// Utility function for CSV parsing
const parseCSVLine = (line: string): string[] => {
  const values: string[] = [];
  let value = "";
  let insideQuotes = false;

  Array.from(line).forEach((char) => {
    if (char === '"') {
      insideQuotes = !insideQuotes;
    } else if (char === "," && !insideQuotes) {
      values.push(value.trim());
      value = "";
    } else {
      value += char;
    }
  });
  values.push(value.trim());
  return values;
};

const LegalAndMedicalModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  isMobile: boolean;
}> = ({ isOpen, onClose, isMobile }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            isMobile={isMobile}
            title="Important Medical & Legal Information"
          >
            <div className="space-y-6">
              {/* Medical Information */}
              <div className="p-4 bg-accent/10 rounded-xl border border-secondary/40">
                <h3 className="text-lg font-bold text-secondary mb-3">
                  Medical Disclaimer
                </h3>
                <ul className="space-y-2 text-secondary/80 font-medium">
                  <li className="flex items-start gap-2">
                    <span className="h-2 w-2 rounded-full bg-secondary mt-2 flex-shrink-0" />
                    There is no single definitive test for PCOS or hormone
                    conditions
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="h-2 w-2 rounded-full bg-secondary mt-2 flex-shrink-0" />
                    Proper diagnosis requires multiple steps including blood
                    tests, scans, and professional consultation
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="h-2 w-2 rounded-full bg-secondary mt-2 flex-shrink-0" />
                    At-home tests are supplementary tools only and not
                    diagnostic devices
                  </li>
                </ul>
              </div>

              {/* Legal Information */}
              <div className="p-4 bg-primary-foreground/20 rounded-xl border border-primary-foreground/80">
                <h3 className="text-lg font-bold text-primary-foreground mb-3">
                  Legal Notice
                </h3>
                <ul className="space-y-2 text-primary-foreground">
                  <li className="flex items-start gap-2">
                    <span className="h-2 w-2 rounded-full bg-primary-foreground mt-2 flex-shrink-0" />
                    This is an educational resource only - not medical advice
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="h-2 w-2 rounded-full bg-primary-foreground mt-2 flex-shrink-0" />
                    We are not affiliated with or endorsed by any testing
                    providers
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="h-2 w-2 rounded-full bg-primary-foreground mt-2 flex-shrink-0" />
                    Information is provided "as is" without warranties
                  </li>
                </ul>
              </div>

              {/* User Responsibilities */}
              <div className="p-4 bg-amber-50 rounded-xl border border-amber-200">
                <h3 className="text-lg font-bold text-amber-900 mb-3">
                  Your Responsibilities
                </h3>
                <ul className="space-y-2 text-amber-900">
                  <li className="flex items-start gap-2">
                    <span className="h-2 w-2 rounded-full bg-amber-500 mt-2 flex-shrink-0" />
                    Always consult healthcare professionals for medical advice
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="h-2 w-2 rounded-full bg-amber-500 mt-2 flex-shrink-0" />
                    Verify all information with test providers directly
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="h-2 w-2 rounded-full bg-amber-500 mt-2 flex-shrink-0" />
                    Seek immediate medical attention for urgent health concerns
                  </li>
                </ul>
              </div>
            </div>
          </BaseModal>
        </>
      )}
    </AnimatePresence>
  );
};

const InformationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  type: "workplace" | "medical";
  isMobile: boolean;
}> = ({ isOpen, onClose, type, isMobile }) => {
  const modalContent = {
    workplace: {
      title: "Check Your Workplace Coverage",
      content: (
        <>
          <p className="text-lg mb-4">
            Many employers offer coverage for hormone testing through:
          </p>
          <ul className="space-y-3">
            {[
              "Healthcare benefits packages",
              "Wellness programs",
              "Employee assistance programs",
            ].map((item) => (
              <li key={item} className="flex items-center gap-2">
                <span className="h-2 w-2 rounded-full bg-primary-foreground" />
                {item}
              </li>
            ))}
          </ul>
          <div className="mt-6 p-4 bg-primary-foreground/10 rounded-lg">
            <p className="font-medium text-primary-foreground">
              Contact your HR department or benefits provider to learn more
              about your coverage options.
            </p>
          </div>
        </>
      ),
    },
    medical: {
      title: "Important Medical Information",
      content: (
        <>
          <p className="text-lg mb-4">
            There is no single definitive test for PCOS (Polycystic Ovary
            Syndrome). A proper diagnosis typically requires:
          </p>
          <ul className="space-y-3">
            {[
              "Blood tests to check hormone levels",
              "Ultrasound scan of your ovaries",
              "Discussion of your symptoms with a healthcare professional",
              "Review of your medical history",
            ].map((item) => (
              <li key={item} className="flex items-center gap-2">
                <span className="h-2 w-2 rounded-full bg-primary-foreground" />
                {item}
              </li>
            ))}
          </ul>
          <div className="mt-6 p-4 bg-primary-foreground/10 rounded-lg">
            <p className="font-medium text-primary-foreground">
              These at-home tests can be a helpful starting point, but they
              should not replace proper medical consultation.
            </p>
          </div>
        </>
      ),
    },
  };

  const selectedContent = modalContent[type];

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      isMobile={isMobile}
      title={selectedContent.title}
    >
      {selectedContent.content}
    </BaseModal>
  );
};

const HormoneTestsTable: React.FC = () => {
  const [testData, setTestData] = useState<TestData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [activeFilter, setActiveFilter] = useState("All");
  const [isMobileView, setIsMobileView] = useState(false);
  const [showWorkplaceModal, setShowWorkplaceModal] = useState(false);
  const [showLegalModal, setShowLegalModal] = useState(true);
  const [hasAcceptedLegal, setHasAcceptedLegal] = useState(false);

  
  const [isScrollVisible, setIsScrollVisible] = useState(false);

  useEffect(() => {
    const toggleScrollVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsScrollVisible(true);
      } else {
        setIsScrollVisible(false);
      }
    };

    window.addEventListener("scroll", toggleScrollVisibility);
    return () => window.removeEventListener("scroll", toggleScrollVisibility);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const filteredData = testData.filter((test) => {
    switch (activeFilter) {
      case "GP Review":
        return test.GP;
      case "Under £100":
        return test.Price < 100;
      case "Most Markers":
        return test.Biomarkers.split(",").length >= 10;
      default:
        return true;
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      const SHEET_ID = "1BOT1qcqiBrGjYIJ_PRXBHDlO4m5zqoCFPBQ18jZ1kzE";
      const SHEET_URL = `https://docs.google.com/spreadsheets/d/${SHEET_ID}/gviz/tq?tqx=out:csv`;

      try {
        const response = await fetch(SHEET_URL);
        if (!response.ok) throw new Error("Network response was not ok");

        const text = await response.text();
        const rows = text.split("\n").map(parseCSVLine);

        const [headers, ...dataRows] = rows;
        if (!headers || headers.length < 8) {
          throw new Error("Invalid data format");
        }

        const formattedData = dataRows.map((row) => ({
          Company: row[0].replace(/^"|"$/g, ""),
          Biomarkers: row[1].replace(/^"|"$/g, ""),
          Results: row[2].replace(/^"|"$/g, ""),
          Support: row[3].replace(/^"|"$/g, ""),
          GP: row[4].replace(/^"|"$/g, "").toLowerCase().includes("yes"),
          Diagnosis: row[5].replace(/^"|"$/g, ""),
          Price: parseFloat(row[6].replace(/^"|"$/g, "")) || 0,
          Link: row[7].replace(/^"|"$/g, ""),
        }));

        setTestData(formattedData);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load test comparison data");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <TableLoading/>
    );
  }

  if (error) {
    return (
      <div
        className="rounded-lg bg-destructive/20 p-4"
        role="alert"
        aria-live="polite"
      >
        <div className="flex items-center gap-2 text-destructive">
          <AlertCircle className="h-5 w-5" aria-hidden="true" />
          <h3 className="font-bold">Error</h3>
        </div>
        <p className="mt-2 text-destructive">{error}</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8 mt-40">
      <LegalAndMedicalModal
        isOpen={showLegalModal && !hasAcceptedLegal}
        onClose={() => {
          setShowLegalModal(false);
          setHasAcceptedLegal(true);
        }}
        isMobile={isMobileView}
      />
      <InformationModal
        isOpen={showWorkplaceModal}
        onClose={() => setShowWorkplaceModal(false)}
        type="workplace"
        isMobile={isMobileView}
      />

      <AnimatePresence>
        {isScrollVisible && (
          <motion.button
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            onClick={scrollToTop}
            className="md:hidden fixed bottom-6 right-6 z-40 bg-primary-foreground text-white p-3 rounded-full shadow-lg hover:shadow-xl transition-all duration-300"
            aria-label="Scroll to top"
          >
            <motion.img
              src={scrollUpImage}
              alt=""
              className="w-6 h-6"
              animate={{ y: [0, -3, 0] }}
              transition={{ repeat: Infinity, duration: 2 }}
            />
          </motion.button>
        )}
      </AnimatePresence>

      <motion.section
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center md:text-left mb-8"
      >
        <h1 className="text-4xl font-bold text-black">
          Compare At-Home Hormone Tests
        </h1>
        <p className="mt-4 text-xl text-black/80">
          Educational comparison to discuss with your healthcare provider
        </p>
      </motion.section>

      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={() => setShowWorkplaceModal(true)}
        className="w-full md:w-auto mb-8 px-6 py-4 rounded-xl shadow-custom bg-offWhite border border-primary/60 text-left hover:shadow-xl transition-all"
      >
        <span className="text-xl font-bold text-secondary flex items-center gap-2">
          <span className="text-2xl">💡</span> Check Your Workplace Coverage
        </span>
        <p className="mt-2 text-gray-600">
          Learn how your employer might cover these tests
        </p>
      </motion.button>

      <div className="mb-8">
        <QuickFilters
          onFilterChange={setActiveFilter}
          activeFilter={activeFilter}
        />
      </div>

      {isMobileView ? (
        <div className="grid gap-4">
          {filteredData.map((test, index) => (
            <MobileTestCard key={`${test.Company}-${index}`} test={test} />
          ))}
        </div>
      ) : (
        <DesktopTable data={filteredData} />
      )}
    </div>
  );
};

export default HormoneTestsTable;
