import React from "react";
import { motion } from "framer-motion";
import { Image as ImageIcon, X, HelpCircle } from "lucide-react";
import chart from "../../../assets/images/ferriman-gallwey-score.png";

interface ReferenceImage {
  url: string;
  caption: string;
  source: string;
  description: string;
}

interface LicensedImage extends ReferenceImage {
  license: {
    name: string;
    url: string;
    terms: string[];
  };
  originalSource: {
    name: string;
    url: string;
  };
}

interface ImageModalProps {
  image: LicensedImage;
  onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ image, onClose }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="fixed inset-0 z-50 p-4 md:p-6 overflow-y-auto bg-black/50"
    onClick={onClose}
  >
    <div className="min-h-full flex items-center justify-center py-16">
      <motion.div
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.95 }}
        onClick={(e) => e.stopPropagation()}
        className="bg-white rounded-xl w-full max-w-3xl mx-auto shadow-lg overflow-hidden"
      >
        {/* Enhanced Header */}
        <div className="flex items-start justify-between p-6 border-b border-border/10 bg-primary/5">
          <div className="pr-8">
            <h3 className="text-xl font-semibold text-secondary">
              How to Assess Your Hair Growth Pattern
            </h3>
            <p className="text-sm text-secondary/70 mt-2">
              A gentle guide to understanding and scoring your hair growth
            </p>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            className="p-2 rounded-full hover:bg-primary/5 transition-colors"
            aria-label="Close guide"
          >
            <X className="w-5 h-5 text-secondary/70" />
          </button>
        </div>

        {/* Step-by-Step Instructions */}
        <div className="p-6 bg-white border-b border-border/10">
          <div className="space-y-4">
            <div className="flex items-start gap-3">
              <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                <span className="text-primary-foreground font-medium">1</span>
              </div>
              <div>
                <h4 className="font-medium text-secondary mb-1">Observe Each Area</h4>
                <p className="text-sm text-secondary/70">
                  Look at the nine body areas shown in the diagram below: upper lip, chin, chest, 
                  upper stomach, lower stomach, thighs, upper back, lower back, and upper arms.
                </p>
              </div>
            </div>
            
            <div className="flex items-start gap-3">
              <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                <span className="text-primary-foreground font-medium">2</span>
              </div>
              <div>
                <h4 className="font-medium text-secondary mb-1">Compare Your Hair Growth</h4>
                <p className="text-sm text-secondary/70">
                  For each area, compare your hair growth to the examples shown:
                </p>
                <ul className="mt-2 space-y-2 text-sm text-secondary/70">
                  <li className="flex items-center gap-2">
                    <span className="w-16 font-medium">Score 0-1:</span>
                    <span>No visible hair to minimal fine hair</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="w-16 font-medium">Score 2-4:</span>
                    <span>Small amount of darker or thicker hair</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="w-16 font-medium">Score 5-8:</span>
                    <span>Moderate amount of darker/thicker hair</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="w-16 font-medium">Score 8+:</span>
                    <span>Significant amount of darker/thicker hair</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="flex items-start gap-3">
              <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                <span className="text-primary-foreground font-medium">3</span>
              </div>
              <div>
                <h4 className="font-medium text-secondary mb-1">Select Your Match</h4>
                <p className="text-sm text-secondary/70">
                  Choose the option that best matches your overall hair growth pattern. 
                  Remember, it's normal to have different scores in different areas.
                </p>
              </div>
            </div>
          </div>

          <div className="mt-6 p-4 bg-primary/5 rounded-lg">
            <div className="flex gap-2">
              <HelpCircle className="w-5 h-5 text-primary-foreground flex-shrink-0 mt-0.5" />
              <div>
                <p className="text-sm text-secondary/70">
                  Remember: Everyone's natural hair patterns are different, and there's no "right" 
                  or "wrong." This is simply a tool to help you communicate with healthcare providers 
                  about your experiences.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Image Section */}
        <div className="relative p-6 bg-white">
          <img
            src={chart}
            alt={image.caption}
            className="w-full h-auto object-contain rounded-lg"
          />
        </div>

        {/* Attribution Section */}
        <div className="p-6 bg-gray-50 border-t border-border/10">
          <div className="space-y-3">
            <div>
              <h4 className="text-sm font-medium text-secondary">Source</h4>
              <a
                href={image.originalSource.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm text-primary-foreground hover:underline"
              >
                {image.originalSource.name}
              </a>
            </div>

            <div>
              <h4 className="text-sm font-medium text-secondary">License</h4>
              <a
                href={image.license.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm text-primary-foreground hover:underline block mb-2"
              >
                {image.license.name}
              </a>
              <ul className="list-disc pl-4 text-xs text-secondary/70 space-y-1">
                {image.license.terms.map((term, index) => (
                  <li key={index}>{term}</li>
                ))}
              </ul>
            </div>
          </div>
          
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            className="mt-6 w-full sm:w-auto px-6 py-2.5 bg-primary/70 text-secondary/70 
                     rounded-lg font-medium hover:bg-primary-foreground hover:text-offWhite 
                     transition-colors duration-200"
          >
            I Understand
          </button>
        </div>
      </motion.div>
    </div>
  </motion.div>
);

export default ImageModal;