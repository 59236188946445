import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios from 'axios';

interface GuideRequest {
  name: string;
  email: string;
  answers: Record<string, number>;
}

interface GuideResponse {
  success: boolean;
  message: string;
}

export const useConsultationGuide = (): UseMutationResult<
  GuideResponse,
  Error,
  GuideRequest,
  unknown
> => {
  return useMutation({
    mutationFn: async (data: GuideRequest) => {
      const response = await axios.post<GuideResponse>(
        'https://paia-backend.azurewebsites.net/api/send-guide',
        data
      );
      return response.data;
    },
  });
};