import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import landingImage1 from "../../../assets/images/landingImage1.png";
import PulsatingButton from "../../buttons/PulsatingButton";
import AnimatedText from "../../animations/AnimatedText";
import ReactGA from "react-ga4";

interface FormData {
  name: string;
  email: string;
}

const TabletHero: React.FC<{
  textAnimationVariants: any;
  mounted: boolean;
  handleScrollToSection: (sectionId: string) => void;
}> = ({ textAnimationVariants, mounted, handleScrollToSection }) => {
  const [formData, setFormData] = useState<FormData>({ name: "", email: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const phrases = [
    {
      text: "Supporting women",
      className: "text-primary-foreground font-semibold",
    },
    { text: "navigate their", className: "" },
    {
      text: "chronic journey",
      className: "text-primary-foreground font-semibold",
    },
    { text: "with", className: "" },
    {
      text: "confidence",
      className: "text-primary-foreground font-semibold italic",
    },
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setIsSubmitted(false);

    ReactGA.event({
      category: 'Buttons',
      action: 'Signup Submitted',
      label: 'Signup Button',
    });

    try {
      const response = await fetch(
        "https://paia-backend.azurewebsites.net/api/waitinglist",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.status === 409) {
        setErrorMessage(
          "You are already on the waitlist! We'll notify you when the app launches."
        );
      } else if (response.ok) {
        setIsSubmitted(true);
        setFormData({ name: "", email: "" });
      } else {
        throw new Error("Something went wrong. Please try again later.");
      }
    } catch (error: any) {
      setErrorMessage(error.message || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section
      id="landing"
      aria-label="Hero section showcasing Paia's services and community"
      className="relative pt-24 px-6 overflow-hidden"
    >
      {/* Background gradient */}
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1, transition: { duration: 1.2 } }}
        className="absolute z-0 pointer-events-none"
        style={{
          width: "700px",
          height: "700px",
          right: "-200px",
          top: "7%",
          background: "#FEE8DA",
          borderRadius: "50%",
          transform: "translateY(-50%)",
          filter: "blur(40px)",
        }}
      />

      {/* Main content */}
      <div className="relative z-10 mx-auto max-w-4xl px-4 mt-20">
        <div className="grid grid-cols-12 gap-6 items-center mb-12">
          {/* Text content */}
          <div className="col-span-7">
            <h2 className="text-[36px] leading-tight mb-8">
              {phrases.map((phrase, index) => (
                <motion.span
                  key={index}
                  custom={index}
                  variants={textAnimationVariants}
                  initial="hidden"
                  animate="visible"
                  className={phrase.className}
                >
                  {phrase.text}{" "}
                </motion.span>
              ))}
            </h2>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
              className="space-y-6"
            >
              <p className="text-[26px] font-bold">
                Be the first to experience the launch of our app
              </p>

              <form onSubmit={handleSubmit} className="space-y-4">
                <motion.div
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.99 }}
                  className="w-5/6"
                >
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    className="shadow-custom w-full px-4 py-3 rounded-xl border border-primary/50
                               focus:outline-none focus:ring-2 focus:ring-primary
                               transition-all duration-300 ease-in-out"
                    aria-label="Your name"
                  />
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.99 }}
                  className="w-5/6"
                >
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter e-mail address"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    className="shadow-custom w-full px-4 py-3 rounded-xl border border-primary/50
                               focus:outline-none focus:ring-2 focus:ring-primary
                               transition-all duration-300 ease-in-out"
                    aria-label="Your email"
                  />
                </motion.div>

                <div className="pt-4">
                  <PulsatingButton
                    type="submit"
                    onClick={() => handleScrollToSection("events")}
                    disabled={isLoading}
                  >
                    <p className="font-medium text-[20px]">
                      {isLoading ? "Signing up..." : "Sign up"}
                    </p>
                  </PulsatingButton>
                </div>
              </form>

              <AnimatePresence>
                {isSubmitted && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="text-secondary font-medium"
                  >
                    Thank you for signing up! We'll be in touch soon.
                  </motion.div>
                )}

                {errorMessage && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="text-primary-foreground font-medium"
                  >
                    {errorMessage}
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          </div>

          {/* Image */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
            className="col-span-5"
          >
            <img
              src={landingImage1}
              alt="Landing"
              className="w-auto h-auto max-h-[32rem] object-contain
                         transform transition-transform duration-700 hover:scale-105"
              loading="eager"
            />
          </motion.div>
        </div>

        <AnimatedText
          // Optional custom overrides
          customTextSize="text-[30px]"
          customMaxWidth="max-w-[85%]"
          customMarginY="mt-20 mb-10"
        />
      </div>
    </section>
  );
};

export default TabletHero;
