import React from "react";
import { motion } from "framer-motion";

interface JoinCommunityProps {
  colour: string;
}

const JoinCommunity: React.FC<JoinCommunityProps> = ({ colour }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="flex flex-col w-full max-w-[1400px] mx-auto 
                 px-4 sm:px-8 md:px-12 lg:px-16 xl:px-20
                 py-8 md:py-12 lg:py-16"
    >
      {/* Title Section */}
      <motion.p
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.2 }}
        className="text-[24px] sm:text-[28px] md:text-[32px] lg:text-[38px] 
                   text-primary-foreground font-semibold flex items-center gap-2
                   w-full max-w-[90%] sm:max-w-[80%] mx-auto"
      >
        Fear of missing out?
        <span className="inline-block text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px]">
          🤭
        </span>
      </motion.p>

      {/* Description Text */}
      <motion.p
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.4 }}
        className="text-black text-[18px] sm:text-[20px] md:text-[24px] lg:text-[28px]
                   mt-4 sm:mt-6 md:mt-8
                   w-full max-w-[90%] sm:max-w-[80%] mx-auto"
      >
        Become part of our thriving{" "}
        <span className="font-semibold inline-block">
          PCOS WhatsApp community
        </span>{" "}
        and connect with{" "}
        <span className="font-semibold inline-block">
          supportive, like-minded individuals
        </span>{" "}
        who truly understand your journey!
      </motion.p>

      {/* Button Container */}
      <motion.div 
        className="w-full max-w-[90%] sm:max-w-[80%] mx-auto 
                   mt-8 sm:mt-10 md:mt-12"
      >
        <motion.a
          href="https://chat.whatsapp.com/LkC4VbRmgls6hmWnldM6JN"
          target="_blank"
          rel="noopener noreferrer"
          className="block w-fit sm:mx-0"
        >
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.6 }}
            className="px-8 sm:px-12 md:px-16 lg:px-20 
                      py-2.5 sm:py-3 md:py-3.5
                      font-medium text-white 
                      text-[16px] sm:text-[18px] md:text-[20px]
                      rounded-[28px] shadow-md hover:shadow-lg 
                      transition-all duration-300"
            style={{ backgroundColor: colour }}
          >
            Join now
          </motion.button>
        </motion.a>
      </motion.div>
    </motion.div>
  );
};

export default JoinCommunity;