import React from "react";
import { ExternalLink } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import ReactGA from "react-ga4";

interface TestData {
  Company: string;
  Biomarkers: string;
  Results: string;
  Support: string;
  GP: boolean;
  Diagnosis: string;
  Price: number;
  Link: string;
}

const handleLinkClick = (linkLabel: string) => {
  ReactGA.event({
    category: 'Table Link',
    action: `Clicked ${linkLabel} Link`,
    label: linkLabel,
  });
};


const MobileTestCard: React.FC<{ test: TestData }> = ({ test }) => (
  <div className="bg-offWhite rounded-[24px] border border-primary-foreground/60 shadow-custom p-5 space-y-4">
    <div className="flex justify-between items-start">
      <h3 className="font-bold text-lg text-black">{test.Company}</h3>
      <span className="font-bold text-lg text-black">
        {test.Price ? `£${test.Price}` : "N/A"}
      </span>
    </div>

    <div className="space-y-4">
      <div>
        <span className="inline-flex items-center rounded-full bg-primary-foreground/10 px-3 py-1.5 text-sm font-semibold text-primary-foreground">
          {test.Biomarkers.split(",").length} markers
        </span>
      </div>

      <div className="space-y-2">
        <div className="text-sm space-y-1">
          <div className="text-black/70">
            <strong className="text-black">Biomarkers:</strong>{" "}
            {test.Biomarkers}
          </div>
          <div className="text-black/70">
            <strong className="text-black">Support:</strong> {test.Support}
          </div>
        </div>

        <div className="flex items-center gap-2">
          <strong className="text-black text-sm">GP Review:</strong>
          <span
            className={`inline-flex items-center justify-center rounded-full px-3 py-1 text-sm font-semibold ${
              test.GP ? "bg-primary-foreground text-offWhite" : "bg-muted/80 text-secondary"
            }`}
          >
            {test.GP ? "Included" : "No"}
          </span>
        </div>
      </div>
    </div>

    <a
      href={test.Link}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => handleLinkClick(test.Company)}
      className="mt-4 inline-flex w-full items-center justify-center gap-2 px-4 py-3 rounded-full bg-primary-foreground text-offWhite font-semibold hover:bg-primary-foreground/90 transition-colors shadow-sm"
    >
      View Details
      <ExternalLink className="h-4 w-4"/>
    </a>
  </div>
);

export default MobileTestCard;
