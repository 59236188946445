import React, { useState } from "react";
import landingImage1 from "../../../assets/images/landingImage1.png";
import PulsatingButton from "../../buttons/PulsatingButton";
import { motion, AnimatePresence } from "framer-motion";
import AnimatedText from "../../animations/AnimatedText";
import ReactGA from "react-ga4";

interface FormData {
  name: string;
  email: string;
}

interface MobileHeroProps {
  textAnimationVariants: any;
  mounted: boolean;
  handleScrollToSection: (sectionId: string) => void;
}

const MobileHero: React.FC<MobileHeroProps> =
    ({textAnimationVariants,
       mounted,
       handleScrollToSection,
     }) => {
  const [formData, setFormData] = useState<FormData>({ name: "", email: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const phrases = [
    {
      text: "Supporting women",
      className: "text-primary-foreground font-semibold",
    },
    { text: "navigate their", className: "" },
    {
      text: "chronic journey",
      className: "text-primary-foreground font-semibold",
    },
    { text: "with", className: "" },
    {
      text: "confidence",
      className: "text-primary-foreground font-semibold italic",
    },
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setIsSubmitted(false);

    ReactGA.event({
      category: 'Buttons',
      action: 'Signup Submitted',
      label: 'Signup Button',
    });

    try {
      const response = await fetch(
          "https://paia-backend.azurewebsites.net/api/waitinglist",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
      );

      if (response.status === 409) {
        setErrorMessage(
            "You are already on the waitlist! We'll notify you when the app launches."
        );
      } else if (response.ok) {
        setIsSubmitted(true);
        setFormData({ name: "", email: "" });
      } else {
        throw new Error("Something went wrong. Please try again later.");
      }
    } catch (error: any) {
      setErrorMessage(error.message || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
      <section
          id="landing"
          aria-label="Hero section showcasing Paia's services and community"
          className="relative pt-40 md:pt-32 px-4 overflow-hidden"
      >
        {/* Background animations */}
        <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                duration: 1.2,
                ease: [0.6, 0.05, 0.01, 0.9],
              },
            }}
            className="absolute z-0 pointer-events-none right-0 top-20 md:right-[5%]"
            style={{
              width: "min(60vh, 400px)",
              height: "min(60vh, 400px)",
              background: "#FEE8DA",
              borderRadius: "50%",
              transform: "translate(25%, -25%)",
              filter: "blur(40px)",
            }}
        />

        <div className="relative z-10 flex flex-col items-center">
          <div className="max-w-[30rem] px-1 flex justify-center items-center mb-4">
            <h2 className="text-[24px] font-regular text-center leading-snug">
              {phrases.map((phrase, index) => (
                  <motion.span
                      key={index}
                      custom={index}
                      variants={textAnimationVariants}
                      initial="hidden"
                      animate="visible"
                      className={phrase.className}
                  >
                    {phrase.text}{" "}
                  </motion.span>
              ))}
            </h2>

            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.5 }}
                className="w-full flex justify-center relative z-10"
            >
              <img
                  src={landingImage1}
                  alt="Landing"
                  className="w-auto h-auto max-h-[16rem] md:max-h-[20rem] object-contain transition-transform"
                  loading="eager"
              />
            </motion.div>
          </div>

          <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 1.4 }}
              className="w-full max-w-[30rem] px-8"
          >
            <p className="text-black text-[22px] font-bold mb-6 text-center mx-auto pt-4">
              Be the first to experience the launch of our app!
            </p>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-4">
                <motion.div
                    whileHover={{ scale: 1.01 }}
                    whileTap={{ scale: 0.99 }}
                >
                  <input
                      type="text"
                      name="name"
                      placeholder="Enter name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                      className="shadow-custom w-full px-5 py-3 rounded-xl border border-primary/50
                             focus:outline-none focus:ring-2 focus:ring-primary
                             transition-all duration-300 ease-in-out"
                      aria-label="Your name"
                  />
                </motion.div>

                <motion.div
                    whileHover={{ scale: 1.01 }}
                    whileTap={{ scale: 0.99 }}
                >
                  <input
                      type="email"
                      name="email"
                      placeholder="Enter e-mail address"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                      className="shadow-custom w-full px-5 py-3 rounded-xl border border-primary/50
                             focus:outline-none focus:ring-2 focus:ring-primary
                             transition-all duration-300 ease-in-out"
                      aria-label="Your email"
                  />
                </motion.div>
              </div>

              <div className="flex justify-center mt-8">
                <PulsatingButton
                    type="submit"
                    onClick={() => handleScrollToSection("events")}
                    disabled={isLoading}
                >
                  <p className="font-medium text-[18px]">
                    {isLoading ? "Signing up..." : "Sign up"}
                  </p>
                </PulsatingButton>
              </div>
            </form>

            <AnimatePresence>
              {isSubmitted && (
                  <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="mt-4 text-secondary font-medium text-center"
                  >
                    Thank you for signing up! We'll be in touch soon.
                  </motion.div>
              )}

              {errorMessage && (
                  <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="mt-4 text-primary-foreground font-medium text-center"
                  >
                    {errorMessage}
                  </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </div>

        <AnimatedText 
  // Optional custom overrides
  customTextSize="text-[20px]"
  customMaxWidth="max-w-[85%]"
  customMarginY="mt-20 mb-10"
/>
      </section>
  );
};

export default MobileHero;
