import React from "react";

interface InfoItemProps {
  icon: React.ElementType;
  title: string;
  description: string;
}

const InfoItem: React.FC<InfoItemProps> = ({ icon: Icon, title, description }) => (
  <div className="flex gap-3">
    <Icon className="h-5 w-5 text-primary-foreground flex-shrink-0" />
    <div>
      <h3 className="font-medium text-secondary">{title}</h3>
      <p className="text-sm text-secondary/70 mt-1">{description}</p>
    </div>
  </div>
);

export default InfoItem