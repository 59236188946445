import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "lucide-react";

const BaseModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    isMobile: boolean;
    title: string;
    children: React.ReactNode;
  }> = ({ isOpen, onClose, isMobile, title, children }) => {

    useEffect(() => {
      if (isOpen) {
        // Store current scroll position
        const scrollPosition = window.pageYOffset;
        // Add styles to prevent scrolling
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollPosition}px`;
        document.body.style.width = '100%';
  
        // Cleanup function to restore scrolling
        return () => {
          document.body.style.removeProperty('overflow');
          document.body.style.removeProperty('position');
          document.body.style.removeProperty('top');
          document.body.style.removeProperty('width');
          // Restore scroll position
          window.scrollTo(0, scrollPosition);
        };
      }
    }, [isOpen]);

    return (
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40"
              onClick={onClose}
            />
            <motion.div
              initial={isMobile ? { y: "100%" } : { x: "-100%" }}
              animate={isMobile ? { y: 0 } : { x: 0 }}
              exit={isMobile ? { y: "100%" } : { x: "-100%" }}
              transition={{ type: "spring", damping: 25, stiffness: 300 }}
              className={`fixed z-50 bg-white ${
                isMobile
                  ? "inset-x-0 bottom-0 rounded-t-3xl max-h-[80vh]"
                  : "left-0 top-0 h-full max-w-lg rounded-r-3xl"
              } p-6 overflow-y-auto`}
            >
              <div className="flex justify-between items-start mb-6">
                <h2 className="text-2xl font-bold text-primary-foreground">{title}</h2>
                <button
                  onClick={onClose}
                  className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                  aria-label="Close modal"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              {children}
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={onClose}
                className="w-full py-4 bg-primary-foreground text-white rounded-xl font-bold shadow-lg mt-8"
              >
                I Understand
              </motion.button>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    );
  };

  export default BaseModal;
  