import React from "react";
import { motion } from "framer-motion";
import {ArrowRight} from "lucide-react";

interface ExpertProps {
  name: string;
  credentials: string;
  image: string;
  role: string;
  linkedin: string;
}

const ExpertCard: React.FC<ExpertProps> = ({
  name,
  credentials,
  image,
  role,
  linkedin,
}) => (
  <a
    href={linkedin}
    target="_blank"
    rel="noopener noreferrer"
    className="block bg-white rounded-xl p-4 hover:shadow-custom transition-all duration-200 group"
  >
    <div className="flex flex-col sm:flex-row gap-4">
      <div className="relative mx-auto sm:mx-0">
        <img
          src={image}
          alt={name}
          className="w-24 h-24 sm:w-28 sm:h-28 rounded-xl object-cover"
          loading="lazy"
        />
        <motion.div
          className="absolute inset-0 border-2 border-primary rounded-xl"
          animate={{ opacity: [0.2, 0.5, 0.2] }}
          transition={{ duration: 2, repeat: Infinity }}
        />
      </div>
      <div className="text-center sm:text-left space-y-1 flex-1">
        <h3
          className="font-semibold text-secondary group-hover:text-primary-foreground 
                    transition-colors duration-300"
        >
          {name}
        </h3>
        <p className="text-sm font-medium text-primary-foreground">
          {credentials}
        </p>
        <p className="text-sm text-secondary/70">{role}</p>
        <div
          className="mt-2 flex items-center justify-center sm:justify-start gap-2 
                      text-xs text-primary-foreground opacity-0 group-hover:opacity-100 
                      transition-opacity duration-300"
        >
          <span>View Profile</span>
          <ArrowRight className="h-3 w-3" />
        </div>
      </div>
    </div>
  </a>
);

export default ExpertCard