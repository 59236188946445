import React from "react";


const TableLoading: React.FC = () => (
    <div className="min-h-screen flex flex-col items-center justify-center p-6">
      {/* Loading Badge - matches header style */}
      <div className="w-fit flex items-center gap-2 px-4 py-2 bg-[#FAFAFA] shadow-custom rounded-[16px] border border-primary mb-8">
        <div className="h-5 w-5 rounded-full bg-primary-foreground/20 animate-pulse" />
        <div className="h-4 w-32 bg-primary-foreground/20 rounded-full animate-pulse" />
      </div>
  
      {/* Loading Table Skeleton */}
      <div className="w-full max-w-4xl rounded-[34px] bg-offWhite border border-primary/60 shadow-custom overflow-hidden p-6">
        {/* Header Skeleton */}
        <div className="space-y-3 mb-8">
          <div className="h-8 w-64 bg-primary/10 rounded-full animate-pulse" />
          <div className="h-4 w-96 bg-primary/10 rounded-full animate-pulse" />
        </div>
        
        {/* Table Skeleton */}
        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <div 
              key={i} 
              className="h-16 bg-white rounded-[16px] border border-primary/10 animate-pulse"
              style={{ animationDelay: `${i * 0.2}s` }}
            />
          ))}
        </div>
      </div>
  
      {/* Loading Text */}
      <div className="mt-6 text-center space-y-2">
      <p className="text-black font-semibold animate-pulse">
        Loading At-Home Hormone Test Comparison
      </p>
      <p className="text-md text-black/70">
        Gathering the latest information for you...
      </p>
    </div>
    </div>
  );
  
  export default TableLoading;