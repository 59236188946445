import React from "react";
import { motion, useInView } from "framer-motion";
import screenshot from "../../assets/images/screenshotTwo.png";
import background from "../../assets/images/background.jpeg";

interface StepProps {
  title: string;
  children: React.ReactNode; // Changed from text to children
  index: number;
  isInView: boolean;
}

// Update the Step component
const Step: React.FC<StepProps> = ({ title, children, index, isInView }) => {
  const variants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        delay: index * 0.1,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      className="bg-white rounded-[28px] p-6 flex flex-col mx-auto shadow-sm w-[300px] h-auto min-h-[276px] sm:min-h-[290px] lg:min-h-[320px]"
      tabIndex={0}
      role="article"
      aria-label={`Step ${index + 1}: ${title}`}
      style={{ willChange: "transform, opacity" }}
    >
      <div className="w-full">
        <div className="bg-[#FDE9E2] text-secondary font-semibold p-4 rounded-[20px] w-full text-[17px] sm:text-[18px] md:text-[20px]">
          {title}
        </div>
      </div>
      <p className="mt-4 text-[16px] sm:text-[17px] lg:text-[18px] font-medium text-gray-700 flex-grow">
        {children}
      </p>
    </motion.div>
  );
};
interface StepsContainerProps {
  steps: any[];
  isInView: boolean;
}

const StepsContainer: React.FC<StepsContainerProps> = ({ steps, isInView }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.1,
      },
    },
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      className="w-full max-w-[1400px] mx-auto grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6 px-4 sm:px-6 lg:px-8 xl:px-12 place-items-center"
    >
      {steps.map((step, index) => (
        <Step key={index} title={step.title} index={index} isInView={isInView}>
          {step.content}
        </Step>
      ))}
    </motion.div>
  );
};

const HowItWorks = () => {
  const steps = [
    {
      title: "1. Start your PCOS Health Journey",
      content: (
        <>
          Our platform <span className="font-bold">empowers women</span> with
          PCOS by using diet to <span className="font-bold">manage</span>{" "}
          symptoms and <span className="font-bold">improve</span> well-being.
        </>
      ),
    },
    {
      title: "2. Log Meals Effortlessly",
      content: (
        <>
          Snap a photo or tap to log your meals. Our{" "}
          <span className="font-bold">AI</span> recognises foods, making
          <span className="font-bold"> tracking quick</span> and{" "}
          <span className="font-bold">easy</span>. No more tedious data entry!
        </>
      ),
    },
    {
      title: "3. Personalised Symptom Tracking & Insights",
      content: (
        <>
          Track symptoms effortlessly, while our AI reveals{" "}
          <span className="font-bold">connections</span> between your diet and
          PCOS patterns.
        </>
      ),
    },
    {
      title: "4. Get AI-Powered Meal Improvements",
      content: (
        <>
          Our <span className="font-bold">AI powered </span> personalised
          guidance with tailored recommendations to support your{" "}
          <span className="font-bold">unique</span> health and wellness{" "}
          <span className="font-bold"> goals</span>.
        </>
      ),
    },
  ];

  const stepsRef = React.useRef(null);

  const isInView = useInView(stepsRef, {
    once: true,
    amount: 0.1,
    margin: "0px 0px -50px 0px",
  });

  return (
    <div className="relative w-full overflow-hidden">
      <div className="relative w-full py-8 sm:py-12">
        <div className="w-full max-w-[1400px] mx-auto flex flex-col lg:flex-row items-center justify-between px-4 sm:px-8 md:px-12 lg:px-16 mb-[-45%] sm:mb-[-35%] md:mb-[-25%] lg:mb-[-15%] relative z-10">
          <motion.h1
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, ease: "easeOut" }}
            viewport={{ once: true }}
            className="font-medium text-[24px] sm:text-[28px] md:text-[32px] lg:text-[38px] text-center lg:text-left mb-8 lg:mb-0 lg:w-1/3"
          >
            How our app works?
          </motion.h1>

          <motion.div
            className="relative w-full lg:w-2/3 flex justify-center lg:justify-end"
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            viewport={{ once: true }}
          >
            <img
              src={screenshot}
              alt="App interface demonstration"
              className="w-auto h-auto max-h-[26rem] sm:max-h-[28rem] md:max-h-[32rem] lg:max-h-[40rem] object-contain relative z-0"
              loading="eager"
            />
          </motion.div>
        </div>

        <div className="relative w-full z-20 mb-4" ref={stepsRef}>
          <div className="absolute inset-0 bg-[#FDD8C6]/50 shadow-[0_-15px_20px_15px_#FDD8C6] transform z-10" />
          <div
            className="absolute inset-0 w-full h-full bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${background})` }}
          />
          <div className="absolute inset-0 bg-[#FDD8C6]/4 shadow-[0_15px_20px_15px_#FDD8C6] transform z-10" />

          <div className="relative pt-20 sm:pt-24 md:pt-28 lg:pt-32 pb-16 sm:pb-20 z-20">
            <StepsContainer steps={steps} isInView={isInView} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
