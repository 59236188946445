import React, { useState } from "react";
import { motion } from "framer-motion";
import { Shield, Info, Clock, ChevronLeft, AlertCircle } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import logoImg from "../assets/icons/paia.png";
import InfoItem from "../components/tools/screening-tool/InfoItem";
import TermsAlert from "../components/tools/screening-tool/TermsAlert";

const DisclaimerScreen: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  const [accepted, setAccepted] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-background">
      <nav className="sticky top-0 p-4 bg-background/95 backdrop-blur-sm z-20 border-b border-primary/10">
        <div className="max-w-2xl mx-auto flex justify-between items-center">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center px-3 py-2 text-secondary hover:text-primary-foreground transition-all duration-300"
            aria-label="Go back"
          >
            <ChevronLeft className="h-4 w-4 mr-2" />
            <span className="text-sm">Back</span>
          </button>
          <img src={logoImg} alt="Paia Logo" className="h-8" />
        </div>
      </nav>

      <main className="px-4 sm:px-6 py-8 max-w-xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-8"
        >
          <header className="text-center space-y-4">
            <h1 className="text-2xl font-bold text-secondary">
              PCOS Educational Assessment
            </h1>
            <p className="text-secondary/80">
              Important Information & Disclaimer
            </p>
          </header>

          <div className="bg-white rounded-xl p-6 shadow-custom space-y-8">
            {/* Key Information */}
            <div className="space-y-6">
              <div className="bg-primary/5 rounded-xl p-4">
                <InfoItem
                  icon={Clock}
                  title="Assessment Period"
                  description="Consider your symptoms and experiences over the past 6-12 months for accurate responses"
                />
              </div>

              <div className="space-y-4">
                <InfoItem
                  icon={Shield}
                  title="Educational Purpose Only"
                  description="This assessment is designed to educate about PCOS symptoms. It is not a diagnostic tool and does not replace medical consultation."
                />
                <InfoItem
                  icon={Info}
                  title="Data Privacy"
                  description="Your responses are private, not stored, and used only to provide educational insights"
                />
                <InfoItem
                  icon={AlertCircle}
                  title="Medical Advice"
                  description="Always consult qualified healthcare professionals for medical advice, diagnosis, or treatment"
                />
              </div>
            </div>

            {/* Agreement Section */}
            <div className="border-t pt-6 space-y-4">
              <label className="flex items-start gap-3 cursor-pointer group">
                <input
                  type="checkbox"
                  checked={accepted}
                  color="#5C3411"
                  onChange={(e) => setAccepted(e.target.checked)}
                  className="mt-1 p-2"
                />
                <span className="text-sm text-secondary/70 group-hover:text-secondary">
                  I acknowledge that this is an educational tool developed in consultation with healthcare professionals. I understand it does not provide medical diagnosis, and I will consult qualified healthcare providers for any medical concerns.
                  <strong>We recommend that individuals under 16 obtain parental or guardian consent before using the screening tool.</strong>
                </span>
              </label>
            </div>
          </div>

          <div className="space-y-4">
            <Link to="/pcos-screening/assessment" className="block">
              <button
                onClick={onStart}
                disabled={!accepted}
                className={`w-full p-4 rounded-xl font-medium transition-all duration-200
                  ${
                    accepted
                      ? "bg-primary-foreground text-offWhite hover:bg-primary-foreground shadow-custom"
                      : "bg-muted text-secondary/40 cursor-not-allowed"
                  }`}
              >
                Begin Educational Assessment
              </button>
            </Link>

            <p className="text-xs text-center text-secondary/60">
              By continuing, you agree to our{" "}
              <button
                onClick={() => setShowTerms(true)}
                className="text-primary-foreground hover:underline"
              >
                Terms & Medical Disclaimer
              </button>{" "}
              {/* and{" "}
              <Link
                to="/privacy"
                className="text-primary-foreground hover:underline"
              >
                Privacy Policy
              </Link> */}
            </p>
          </div>
        </motion.div>
      </main>
      <TermsAlert show={showTerms} onClose={() => setShowTerms(false)} />
    </div>
  );
};

export default DisclaimerScreen;