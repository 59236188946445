import React from "react";
import { useViewport } from "../../hooks/useViewport";
import { CTAButton } from "./hero/CTAButton";
import { BackgroundBlur } from "./hero/BackgroundBlur";
import { StatCard } from "./hero/StatCard";
import light from "../../assets/icons/light-bulb.png";
import screenshot from "../../assets/images/screenshot.png";
import { motion } from "framer-motion";

const HeroTwo: React.FC = () => {
  const { isMobile } = useViewport();

  const Content = () => (
    <div className="bg-[#FFFBF8] py-1">
      <div className="relative bg-[#FFFBF8] py-6 md:py-12 mt-6 md:mt-8">
        {/* Adjusted background blur */}
        <div className="absolute inset-0 overflow-hidden">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className={`absolute z-0 pointer-events-none bg-[#FEE8DA] rounded-full blur-[40px]
                       ${
                         isMobile
                           ? "w-[350px] h-[300px] left-[-100px] top-[10%]"
                           : "w-[550px] h-[500px] left-[-200px] top-[10%]"
                       }`}
          />
        </div>

        <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-[1400px]">
          <div className="flex flex-col md:flex-row justify-start items-start gap-4 lg:gap-16">
            {/* Screenshot and Stats Container for Mobile */}
            <div
              className={`relative z-10 ${
                isMobile ? "w-full" : "w-full md:w-1/2 lg:w-auto"
              }`}
            >
              <div className="flex flex-row items-start gap-2">
                <img
                  src={screenshot}
                  alt="App screenshot showing PCOS screening tool"
                  className="w-auto h-auto max-h-[22rem] md:max-h-[32rem] lg:max-h-[44rem] 
                           object-contain transition-all duration-300"
                  loading="eager"
                />
                {isMobile && (
                  <div className="flex-1 max-w-[250px] mt-8">
                    <StatCard percentage={70} isMobile={true} className="" />
                  </div>
                )}
              </div>
            </div>
            {/* Content */}
            <div className="relative z-10 flex flex-col w-full lg:w-auto">
              {!isMobile && (
                <StatCard
                  percentage={70}
                  isMobile={false}
                  className=""
                />
              )}

              {/* CTA Sections */}
              <div className="w-full my-4">
                {/* Screening Tool CTA */}
                <div className="flex flex-col space-y-3 md:my-8">
                  <h2 className="text-[20px] sm:text-[22px] md:text-[26px] lg:text-[32px] font-bold">
                    Take our PCOS educational tool now
                  </h2>
                  <p className="text-[16px] sm:text-[18px] md:text-[20px] py-2 lg:text-[24px]">
                    Our educational tool helps identify potential signs of
                    polycystic ovary syndrome.
                  </p>
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    transition={{ duration: 0.2 }}
                  >
                    <CTAButton to="/pcos-screening" variant="primary">
                      Take your assessment
                    </CTAButton>
                  </motion.div>
                </div>
                {/* Blood Tests CTA */}
                <div className="flex flex-col gap-4 mt-8 md:mt-12">
                  <p className="text-[16px] sm:text-[18px] md:text-[20px] py-2 lg:text-[24px]">
                    Blood tests can provide valuable insights into your hormonal
                    health.
                  </p>
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    transition={{ duration: 0.2 }}
                  >
                    <CTAButton to="/test-comparison" variant="secondary">
                      Compare at home blood tests
                    </CTAButton>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Citation */}
      <a target="__blank" href="https://www.who.int/news-room/fact-sheets/detail/polycystic-ovary-syndrome" className="w-full flex justify-end ">
        <p className="w-fit italic font-semibold text-grey px-4 md:px-8 text-[10px] md:text-[12px] hover:text-black transition duration-300">*Source: World Health Organisation</p>
      </a>
    </div>
  );

  return (
    <section
      id="signup"
      aria-label="PCOS Screening and Testing Information"
      className="w-full overflow-hidden"
    >
      {/* Divider */}
      <div className="rounded-lg shadow-custom h-[0.15rem] md:h-[0.2rem] bg-primary mx-4 md:mx-16 lg:mx-40" />

      {/* Header */}
      <div className="flex flex-col md:flex-row justify-center items-center md:items-start gap-2 md:gap-3 mt-6 sm:mt-8 md:mt-16 px-4">
        <img
          src={light}
          alt="Lightbulb icon"
          className="w-auto h-auto max-h-[2.5rem] sm:max-h-[3rem] object-contain 
                   transition-all duration-300"
          loading="eager"
        />
        <h1
          className="text-[20px] sm:text-[24px] md:text-[32px] text-secondary text-center md:text-left 
                    w-full sm:w-2/3 md:w-fit pb-6 md:pt-0"
        >
          <span className="font-bold">Learn more</span> about{" "}
          <span className="font-bold">your</span> symptoms
        </h1>
      </div>

      <Content />
    </section>
  );
};

export default HeroTwo;
