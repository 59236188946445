import React from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";
import { motion } from "framer-motion";

interface ToolCardProps {
  title: string;
  description: string;
  linkText: string;
  linkTo: string;
  colour: string;
  colourTwo?: string;
  icon: string;
  stats?: Array<{ value: string; label: string; image?: string }>;
  primary?: boolean;
}

const ToolCard: React.FC<ToolCardProps> = ({
  title,
  colour,
  description,
  linkText,
  colourTwo,
  linkTo,
  icon,
  stats,
  primary = false,
}) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    transition={{ duration: 0.2 }}
  >
    <Link to={linkTo} className="block h-full" aria-label={`Open ${title}`}>
      <div
        className={`group h-full w-full p-4 sm:p-6 md:p-8 rounded-[40px] transition-all duration-300
          ${
            primary
              ? "bg-gradient-to-br from-primary/80 to-muted/60 border border-primary text-secondary shadow-custom hover:shadow-xl"
              : "bg-gradient-to-br from-[#F0AEAE] to-[#FBEBDD] shadow-custom hover:shadow-xl border border-primary-foreground hover:bg-primary/5"
          }`}
      >
        <div className="flex flex-col h-full space-y-3 sm:space-y-4 md:space-y-6">
          {/* Icon */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            className={`p-2 sm:p-3 rounded-xl w-fit border rounded-[16px] ${
              primary ? "bg-white/50 border-primary" : "bg-white/50 border-primary-foreground"
            }`}
          >
            <img
              src={icon}
              alt={"icon"}
              className={`h-5 w-5 sm:h-6 sm:w-6 md:h-7 md:w-7 ${
                primary ? "text-secondary" : "text-primary-foreground"
              }`}
            />
          </motion.div>

          {/* Content */}
          <div className="flex-1 space-y-2 sm:space-y-3 md:space-y-4">
            <h3
              className={`font-bold text-[20px] md:text-[30px] leading-tight ${
                primary ? "text-secondary" : "text-black"
              }`}
            >
              {title}
            </h3>
            <p
              className={`text-[15px] md:text-[18px] lg:text-[24px] leading-relaxed ${
                primary ? "text-black" : "text-black"
              }`}
            >
              {description}
            </p>
          </div>

          {/* Stats */}
          {stats && (
            <div className="grid grid-cols-2 gap-2 sm:gap-4 w-fit">
              {stats.map((stat, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.03 }}
                  className={`rounded-[20px] p-2 sm:p-3 text-primary-foreground text-center border
                    ${primary ? "bg-white/50 border-primary" : "bg-white/50 border-primary-foreground"}`}
                >
                  <div className="flex items-center justify-center gap-1">
                    {stat.image && (
                      <img 
                        src={stat.image} 
                        alt={stat.value} 
                        className="h-4 w-4 sm:h-5 sm:w-5 md:h-6 md:w-6"
                      />
                    )}
                    <span className="block text-sm sm:text-base md:text-lg font-bold">
                      {stat.value}
                    </span>
                  </div>
                  <span className="text-[12px] md:text-[16px] text-black/90">
                    {stat.label}
                  </span>
                </motion.div>
              ))}
            </div>
          )}

          {/* CTA */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            className={`inline-flex items-center gap-2 w-fit rounded-[30px] px-4 sm:px-6 md:px-8 py-2 
              group-hover:gap-3 transition-all duration-300 text-[14px] md:text-[16px] 
              text-offWhite leading-relaxed ${
                primary ? "bg-secondary" : "bg-primary-foreground"
              }`}
          >
            <span>{linkText}</span>
            <ArrowRight className="h-3 w-3 sm:h-4 sm:w-4" />
          </motion.div>
        </div>
      </div>
    </Link>
  </motion.div>
);

export default ToolCard;