import React from "react";
import { motion } from "framer-motion";
import teamMember1 from "../../assets/images/mahamFrame.png";
import teamMember2 from "../../assets/images/marahFrame.png";
import teamMember3 from "../../assets/images/sabihahFrame.png";
import teamMember4 from "../../assets/images/srezonFrame.png";
import doctor1 from "../../assets/images/osama.jpg";
import doctor2 from "../../assets/images/zahra.jpg";
import nutritionist1 from "../../assets/images/emily.png";
import nutritionist2 from "../../assets/images/victoria.png";
import nutritionist3 from "../../assets/images/georgia.png";

interface TeamMemberProps {
  image: string;
  name: string;
  role: string;
  title?: string;
  qualifications?: string;
  delay: number;
  isMobile?: boolean;
}

const TeamMember: React.FC<TeamMemberProps> = ({
  image,
  name,
  role,
  title,
  qualifications,
  delay,
  isMobile = false,
}) => {
  const baseClasses =
    "flex flex-col items-center space-y-4 md:space-y-6 text-[18px] md:text-[22px]";
  const mobileClasses = isMobile
    ? "gradient-box rounded-[80px] px-8 py-12 h-full w-[230px]"
    : "m-4";

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, delay }}
      className={`${baseClasses} ${mobileClasses}`}
    >
      {title && <p className="italic font-light text-secondary">{title}</p>}
      <div className="relative">
        <img
          src={image}
          alt={name}
          className="w-24 h-24 md:w-32 md:h-32 rounded-full object-cover"
        />
      </div>
      <div
        className={`text-center ${
          isMobile ? "max-w-[7rem]" : "w-full max-w-[230px]"
        }`}
      >
        <p className="font-bold text-secondary">{name}</p>
        <p
          className={`font-regular text-secondary ${
            isMobile ? "text-[18px]" : "text-[16px] md:text-[18px]"
          }`}
        >
          {role}
        </p>
        {qualifications && (
          <p className="text-[18px] md:text-[20px] text-secondary/80 mt-1">
            {qualifications}
          </p>
        )}
      </div>
    </motion.div>
  );
};

const Team: React.FC = () => {
  const coFounders = [
    { image: teamMember1, name: "Maham", role: "Growth & Digital Marketing" },
    { image: teamMember2, name: "Marah", role: "Software Engineer" },
    { image: teamMember3, name: "Sabihah", role: "Branding & Design" },
    { image: teamMember4, name: "Srezon", role: "Software Engineer" },
  ];

  const doctors = [
    {
      image: doctor1,
      name: "Dr. Osama Abughazza",
      role: "Consultant Gynaecologist",
      qualifications: "MD, MRCOG, MSc",
    },
    {
      image: doctor2,
      name: "Dr. Zahra Ameen",
      role: "Consultant Gynaecologist",
      qualifications: "MBBS BSc MRCOG",
    },
  ];

  const nutritionists = [
    {
      image: nutritionist1,
      name: "Emily Charlton",
      role: "Performance Nutritionist & Student Dietitian",
    },
    {
      image: nutritionist2,
      name: "Victoria Serweytens de Mercx",
      role: "Dietitian and Public Health Nutritionist (ANutr)",
    },
    {
      image: nutritionist3,
      name: "Georgia Froude",
      role: "Student Nutritionist",
    },
  ];

  return (
    <section
      id="team"
      aria-labelledby="team-heading"
      className="w-full flex flex-col items-center justify-center py-12"
    >
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        id="team-heading"
        className="text-primary-foreground font-bold text-center font-hand text-[36px] md:text-[42px] md:mb-10"
      >
        Meet the Paia team!
      </motion.h2>

      {/* Co-Founders Section */}
      <div className="mb-20 w-full">
        {/* Desktop/Tablet View */}
        <div className="hidden md:block  max-w-6xl mx-auto">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="gradient-box rounded-[70px] px-12 py-8"
          >
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-8 max-w-6xl mx-auto">
              {coFounders.map((member, index) => (
                <TeamMember
                  key={member.name}
                  image={member.image}
                  name={member.name}
                  role={member.role}
                  title="Co-Founder"
                  delay={0.2 + index * 0.1}
                />
              ))}
            </div>
          </motion.div>
        </div>

        {/* Mobile Scrollable Version */}
        <div className="block md:hidden overflow-x-auto hide-scrollbar">
          <div className="flex gap-4 px-8 py-12">
            {coFounders.map((member, index) => (
              <div key={member.name} className="w-[300px]">
                <TeamMember
                  image={member.image}
                  name={member.name}
                  role={member.role}
                  title="Co-Founder"
                  delay={0.2 + index * 0.1}
                  isMobile={true}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Medical Advisors Section */}
      <div className="mb-20">
        <motion.h3
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-secondary text-center text-[28px] md:text-[32px] mb-12"
        >
          Medical Advisors
        </motion.h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-4xl mx-auto">
          {doctors.map((doctor, index) => (
            <TeamMember
              key={doctor.name}
              image={doctor.image}
              name={doctor.name}
              role={doctor.role}
              qualifications={doctor.qualifications}
              delay={0.4 + index * 0.1}
            />
          ))}
        </div>
      </div>

      {/* Nutrition Team Section */}
      <div>
        <motion.h3
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-secondary text-center text-[28px] md:text-[32px] mb-12"
        >
          Nutrition Team
        </motion.h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {nutritionists.map((nutritionist, index) => (
            <TeamMember
              key={nutritionist.name}
              image={nutritionist.image}
              name={nutritionist.name}
              role={nutritionist.role}
              delay={0.6 + index * 0.1}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
