import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useBeforeUnload, useBlocker } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronLeft } from "lucide-react";
import { pcosToolData } from "./pcosToolQuestions";
import ResultsCard from "./ResultsCard";
import logoImg from "../../../assets/icons/paia.png";
import QuestionCard from "./QuestionCard";
import ProgressBar from "./ProgressBar";
import Alert from "./Alert";
import ReactGA from "react-ga4";

interface UnsavedChangesAlert {
  show: boolean;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

interface Option {
  text: string;
  score: number;
  explanation?: string;
}

interface AnswerState {
  answer: number;
  visited: boolean;
}

const SymptomChecker: React.FC = () => {
  const [isNavigating, setIsNavigating] = useState(false);
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<Record<string, AnswerState>>({});
  const [maxVisitedQuestion, setMaxVisitedQuestion] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [progress, setProgress] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<
    Record<string, Option>
  >({});
  const [alert, setAlert] = useState<UnsavedChangesAlert>({
    show: false,
    message: "",
    onConfirm: () => {},
    onCancel: () => {},
  });

  // Calculate total score based on answers
  const totalScore = useCallback(
    () => Object.values(answers).reduce((sum, ans) => sum + ans.answer, 0),
    [answers]
  );

  const hasUnsavedChanges = Object.keys(answers).length > 0 && !quizCompleted;
  const blocker = useBlocker(hasUnsavedChanges && !isNavigating);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (hasUnsavedChanges) {
          event.preventDefault();
          return "";
        }
      },
      [hasUnsavedChanges]
    )
  );

  const handleNavigation = useCallback(
    (proceedCallback: () => void) => {
      if (hasUnsavedChanges) {
        setAlert({
          show: true,
          message:
            "Your progress will be lost if you leave. Are you sure you want to continue?",
          onConfirm: () => {
            setAlert((prev) => ({ ...prev, show: false }));
            setIsNavigating(true);
            proceedCallback();
          },
          onCancel: () => {
            setIsNavigating(false);
            setAlert((prev) => ({ ...prev, show: false }));
          },
        });
      } else {
        proceedCallback();
      }
    },
    [hasUnsavedChanges]
  );

  useEffect(() => {
    if (blocker.state === "blocked" && !isNavigating) {
      handleNavigation(() => blocker.proceed());
    }
  }, [blocker, handleNavigation, isNavigating]);

  const handleNavigateAway = useCallback(() => {
    handleNavigation(() => navigate(-1));
  }, [navigate, handleNavigation]);

  const handlePreviousQuestion = useCallback(() => {
    if (currentQuestion > 0) {
      const previousQuestion = currentQuestion - 1;
      setCurrentQuestion(previousQuestion);
      setShowInfo(false);

      const previousQuestionKey = pcosToolData[previousQuestion].key;
      const previousSelection = selectedOptions[previousQuestionKey];
      setSelectedOption(previousSelection || null);
    }
  }, [currentQuestion, selectedOptions]);

  useEffect(() => {
    const progressValue = ((currentQuestion + 1) / pcosToolData.length) * 100;
    setProgress(
      Math.min(
        progressValue,
        ((maxVisitedQuestion + 1) / pcosToolData.length) * 100
      )
    );
  }, [currentQuestion, maxVisitedQuestion]);

  const handleOptionSelect = useCallback(
    (option: Option) => {
      const questionKey = pcosToolData[currentQuestion].key;
      setSelectedOptions((prev) => ({
        ...prev,
        [questionKey]: option,
      }));
      setSelectedOption(option);

      ReactGA.event({
        category: 'Quiz',
        action: 'Answered Question',
        label: questionKey,
        value: option.score,
      });

    },
    [currentQuestion]
  );

  const handleNextQuestion = useCallback(() => {
    if (!selectedOption) return;

    const questionKey = pcosToolData[currentQuestion].key;

    // Update answers with visited state
    setAnswers((prev) => ({
      ...prev,
      [questionKey]: {
        answer: selectedOption.score,
        visited: true,
      },
    }));

    // Update max visited question if moving forward
    if (currentQuestion >= maxVisitedQuestion) {
      setMaxVisitedQuestion(currentQuestion);
    }

    if (currentQuestion < pcosToolData.length - 1) {
      const nextQuestion = currentQuestion + 1;
      setCurrentQuestion(nextQuestion);

      // Restore previous answer if it exists
      const nextQuestionKey = pcosToolData[nextQuestion].key;
      const previousAnswer = selectedOptions[nextQuestionKey];
      setSelectedOption(previousAnswer || null);
      setShowInfo(false);
    } else {
      setQuizCompleted(true);
    }
  }, [currentQuestion, selectedOption, maxVisitedQuestion, selectedOptions]);

  // Function to jump to a specific question (for progress bar navigation)
  const handleQuestionJump = useCallback(
    (questionIndex: number) => {
      if (questionIndex <= maxVisitedQuestion) {
        setCurrentQuestion(questionIndex);
        const questionKey = pcosToolData[questionIndex].key;
        setSelectedOption(selectedOptions[questionKey] || null);
        setShowInfo(false);
      }
    },
    [maxVisitedQuestion, selectedOptions]
  );

  return (
    <div className="min-h-screen bg-background" role="main">
      <Alert {...alert} />
      {!quizCompleted && (
        <nav
          className="sticky top-0 p-4 bg-background/95 backdrop-blur-sm z-20 border-b border-border/10"
          role="navigation"
          aria-label="Main navigation"
        >
          <div className="max-w-2xl mx-auto flex justify-between items-center">
            <button
              onClick={handleNavigateAway}
              className="inline-flex items-center px-3 py-2 text-secondary hover:text-primary-foreground transition-colors duration-300"
              aria-label="Go back to previous page"
            >
              <ChevronLeft className="h-4 w-4 mr-2" aria-hidden="true" />
              <span className="text-sm">Back</span>
            </button>
            <img
              src={logoImg}
              alt="Paia Logo"
              className="h-8 mx-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        </nav>
      )}

      <main
        className={`px-4 sm:px-6 py-8 ${
          quizCompleted ? "max-w-4xl" : "max-w-2xl"
        } mx-auto`}
        role="main"
        aria-label={
          quizCompleted ? "Assessment Results" : "PCOS Assessment Questions"
        }
      >
        <AnimatePresence mode="wait">
          {!quizCompleted ? (
            <motion.div
              key="quiz"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              role="form"
              aria-label={`Question ${currentQuestion + 1} of ${
                pcosToolData.length
              }`}
            >
              <ProgressBar
                progress={progress}
                currentQuestion={currentQuestion}
                totalQuestions={pcosToolData.length}
                maxVisited={maxVisitedQuestion}
                onQuestionClick={handleQuestionJump}
                visitedQuestions={Object.keys(answers).map((key) =>
                  pcosToolData.findIndex((q) => q.key === key)
                )}
              />
              <QuestionCard
                question={pcosToolData[currentQuestion]}
                selectedOption={selectedOption}
                onOptionSelect={handleOptionSelect}
                onNext={handleNextQuestion}
                onPrevious={handlePreviousQuestion}
                showPrevious={currentQuestion > 0}
                showInfo={showInfo}
                onToggleInfo={() => setShowInfo(!showInfo)}
                isLastQuestion={currentQuestion === pcosToolData.length - 1}
                canProceed={!!selectedOption}
              />
            </motion.div>
          ) : (
            <motion.div
              key="results"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              role="region"
              aria-label="Assessment Results"
            >
              <ResultsCard
                answers={Object.entries(answers).reduce(
                  (acc, [key, value]) => ({
                    ...acc,
                    [key]: value.answer,
                  }),
                  {}
                )}
                questions={pcosToolData}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </main>
    </div>
  );
};

export default SymptomChecker;
