import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

interface CTAButtonProps {
  to: string;
  variant?: "primary" | "secondary";
  children: React.ReactNode;
  className?: string;
}

export const CTAButton: React.FC<CTAButtonProps> = ({
  to,
  variant = "primary",
  children,
  className = ""
}) => {
  const baseStyles = `
    w-fit rounded-[30px] font-semibold transition-all duration-300
    py-2 sm:py-3 px-4 sm:px-6
    text-[16px] md:text-[20px] lg:text-[24px]
    whitespace-normal break-words
    max-w-full sm:max-w-none
  `;

  const variantStyles = variant === "primary"
    ? "bg-primary-foreground text-white hover:bg-primary-foreground/90"
    : "bg-[#FCF1E7] border-primary-foreground border-[0.13rem] text-primary-foreground hover:bg-[#FCF1E7]/90";

  return (
    <Link
      to={to}
      className={`${baseStyles} ${variantStyles} ${className}`}
    >
      {children}
    </Link>
  );
};

